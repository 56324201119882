import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Tab, Tabs, Form } from "react-bootstrap";
import { Breadcrumb, Pagination } from "../../../components";
import PageLayout from "../../../layouts/PageLayout";
import data from "../../../data/master/admin/payments.json";
import { Box, Item, Anchor, Button } from "../../../components/elements";
import { CardLayout, TabCard } from "../../../components/cards";
import {
  EcommerceCard,
  SalesCard,
  ProductsCard,
  RevenueCard,
  ActivityCard,
  OrdersCard
} from "../../../components/cards";
import ClientsTable from "../../../components/admin/Clients";
import AdminAccountsPage from "../../../components/admin/Accounts";
import PaymentsPage from "../../../components/admin/Payments";
import {
  Skeleton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Spinner
} from "@chakra-ui/react";
import { LabelField } from "../../../components/fields";
import DepositTable from "./table";
import { useQuery } from "react-query";
import {
  adminGetAllAccounts,
  approveRejectRequestService,
  getAllAccountsService,
  getAllIBPlans,
  getAllIBRequestsService,
  getAllIbPlanService,
  getAllUsersServices,
  getRequestsByType,
  makeUserIbService
} from "../../../services/admin.service";
import { useShowToast } from "../../../hooks";
import _ from "lodash";
import ChangeIbPlanForm from "./add-new-user-form";
import {
  getAccountPlans,
  getPaymentMethodsService
} from "../../../services/client";

export default function AdminAddInternalTransferRequest({
  type = "Internal Transfer"
}) {
  return (
    <PageLayout
      title="Internal Transfer"
      breadcrumb={[
        { title: "Home", path: "/admin/dashboard" },
        {
          title: type
        }
      ]}
    >
      <Row>
        <DepositsPaymentPage type={type} />
      </Row>
    </PageLayout>
  );
}

const Loader = () => {
  return <Skeleton minHeight="500px" />;
};

const DepositsPaymentPage = ({ type }) => {
  const { isLoading, error, data, refetch } = useQuery(["admin-accounts"], () =>
    adminGetAllAccounts()
  );

  const accounts = data?.data?.data?.accounts?.data?.data ?? [];

  return (
    <>
      <Col xl={12}>
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <ChangeIbPlanForm accounts={accounts} type={type} />
          )}
        </>
      </Col>
    </>
  );
};
