import React from "react";
import { Box, List, Heading } from "./elements";

export default function Breadcrumb({ title, subtitle, children, className }) {
  return (
    <Box className={`mc-breadcrumb ${className ? className : ""}`}>
      <Box>
        <Heading className="mc-breadcrumb-title">{title}</Heading>
        {subtitle ? <p style={{ fontSize: "12px" }}>{subtitle}</p> : null}
      </Box>

      <List className="mc-breadcrumb-list">{children}</List>
    </Box>
  );
}
