import {
  Grid,
  Spinner,
  Box as CBox,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton
} from "@chakra-ui/react";
import { useFormik, getIn } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import _ from "lodash";
import { LabelField } from "../../../components/fields";
import { Box, Icon, Label } from "../../../components/elements";

import {
  addCommissionPlanToUserService,
  deleteCommissionPlanFromUserService,
  getAllCommissionPlanOfAUserService
} from "../../../services/admin.service";
import { useShowToast } from "../../../hooks";
import CustomReactSelect from "../../../components/fields/CustomReactSelect";
import { useQuery } from "react-query";
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "../../../components/elements/Table";
import { FaTrash } from "react-icons/fa";

const thead = ["Level", "Commission Per Lot", ""];
const SignupSchema = Yup.object().shape({
  user_id: Yup.number().required("Required"),
  IB_Structure_ID: Yup.number().required("Required"),
  comment: Yup.string()
});

let type = "";
// accounts methods
const ChangeIbPlanForm = ({ ibs, allPlans }) => {
  const showToast = useShowToast();

  const formik = useFormik({
    initialValues: {
      user_id: undefined,
      IB_Structure_ID: undefined
    },
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);

      const planExists = _.find(data?.data?.data?.data, (p) => {
        return p?.IB_StructureID == values?.IB_Structure_ID ? true : false;
      });

      if (planExists) {
        actions.setFieldError("IB_Structure_ID", "Plan already exists");
        actions.setSubmitting(false);
        return;
      }

      //addCommissionPlanToUserService
      try {
        const result = await addCommissionPlanToUserService({
          ...values
        });

        if (result?.data?.success) {
          showToast("Success", "Request created successfully");
          actions.setSubmitting(false);

          return;
        }
      } catch (e) {
        console.log(e);
      }

      showToast("Error", "Something went wrong", "error");
      actions.setSubmitting(false);
    },
    validationSchema: SignupSchema
  });

  const { data, isLoading, refetch } = useQuery(
    ["adminalluserCommissionplans", formik?.values?.user_id],
    () => getAllCommissionPlanOfAUserService(formik?.values?.user_id),
    {
      enabled: !!formik?.values?.user_id
    }
  );

  useEffect(() => {
    if (!formik?.isSubmitting && !isLoading && formik?.values?.user_id) {
      refetch();
    }
  }, [formik?.isSubmitting]);

  useEffect(() => {
    refetch();
  }, [formik?.values?.user_id]);

  const [planToDelete, SetPlanToDelete] = useState(null);

  const allOptions = useMemo(() => {
    return [
      {
        label: "Select User",
        value: undefined
      },
      ..._.map(_.sortBy(ibs, "name"), (_u) => {
        return {
          label: `${_u?.name} (${_u?.email})`,
          value: _u?.id
        };
      })
    ];
  }, [ibs]);

  const allPlansOptions = useMemo(() => {
    return [
      {
        label: "Select Plan",
        value: undefined
      },
      ..._.map(_.sortBy(allPlans, "name"), (_u) => {
        return {
          label: `${_u?.name}`,
          value: _u?.id
        };
      })
    ];
  }, [data?.data?.data?.data]);

  useEffect(() => {
    formik?.setFieldValue("IB_Structure_ID", undefined);
  }, [formik?.values?.user_id]);

  const cancelRef = useRef();

  const [isDeleting, setIsDeleting] = useState(false);

  const handlePlanDelete = async () => {
    setIsDeleting(true);

    try {
      const result = await deleteCommissionPlanFromUserService(
        planToDelete?.id
      );

      if (result?.data?.success) {
        showToast("Success", "Plan deleted successfully");
        refetch();
        SetPlanToDelete(null);
        setIsDeleting(false);
        return;
      }
    } catch (e) {
      console.log(e);
    }

    showToast("Error", "Something went wrong", "error");

    setIsDeleting(false);
  };

  const onClose = () => {
    SetPlanToDelete(null);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid gap={4}>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <Box>
              <Grid gap={4}>
                <Box>
                  <CustomReactSelect
                    options={allOptions}
                    label="Select IB"
                    errorMessage={
                      formik?.errors?.user_id && formik?.touched?.user_id
                        ? formik?.errors?.user_id
                        : false
                    }
                    value={
                      formik?.values?.user_id
                        ? _.find(allOptions, (opt) =>
                            opt?.value === formik?.values?.user_id
                              ? true
                              : false
                          )
                        : { label: "Select User", value: undefined }
                    }
                    onChange={(e) => {
                      formik?.setFieldValue("user_id", e?.value);
                    }}
                  />
                </Box>

                <CustomReactSelect
                  options={allPlansOptions}
                  label="Select Plan To Add"
                  errorMessage={
                    formik?.errors?.IB_Structure_ID &&
                    formik?.touched?.IB_Structure_ID
                      ? formik?.errors?.IB_Structure_ID
                      : false
                  }
                  value={
                    formik?.values?.IB_Structure_ID
                      ? _.find(allOptions, (opt) =>
                          opt?.value === formik?.values?.IB_Structure_ID
                            ? true
                            : false
                        )
                      : {
                          label: "Select Plan",
                          value: undefined
                        }
                  }
                  onChange={(e) => {
                    formik?.setFieldTouched("IB_Structure_ID", true);
                    formik?.setFieldValue("IB_Structure_ID", e?.value);
                  }}
                />

                <Box>
                  <button
                    type="submit"
                    className="mc-btn primary"
                    style={{ minHeight: "42px" }}
                  >
                    {formik?.isSubmitting ? <Spinner /> : "Add Plan"}
                  </button>
                </Box>
              </Grid>
            </Box>

            <div style={{ marginTop: "16px" }}>
              {formik?.values?.user_id ? (
                <>
                  {isLoading ? (
                    <div style={{ padding: "16px", textAlign: "center" }}>
                      <Spinner mx="auto" />
                    </div>
                  ) : (
                    <CBox paddingTop="16px">
                      <Box className="mc-table-responsive">
                        <Table className="mc-table product">
                          <Thead className="mc-table-head primary">
                            <Tr>
                              <Th>Plan Name</Th>
                              <Th></Th>
                            </Tr>
                          </Thead>
                          <Tbody className="mc-table-body even">
                            {data?.data?.data?.data?.length ? (
                              <>
                                {_.map(data?.data?.data?.data, (p) => (
                                  <Tr>
                                    <Td>{p?.IB_StructureName}</Td>
                                    <Td>
                                      <CBox
                                        cursor="pointer"
                                        as={FaTrash}
                                        onClick={() => {
                                          SetPlanToDelete(p);
                                        }}
                                      />
                                    </Td>
                                  </Tr>
                                ))}
                              </>
                            ) : (
                              <Tr>
                                <Td colSpan={2}>No Plans Present</Td>
                              </Tr>
                            )}
                          </Tbody>
                        </Table>
                      </Box>
                    </CBox>
                  )}
                </>
              ) : null}
            </div>
          </Grid>

          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <Box></Box>
          </Grid>

          <Box style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ marginLeft: "auto" }}></div>
          </Box>
        </Grid>
      </form>

      <AlertDialog
        isOpen={planToDelete ? true : false}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remove Plan?
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You want to remove {planToDelete?.IB_StructureName}{" "}
              ?
            </AlertDialogBody>

            <AlertDialogFooter>
              <div>
                {isDeleting ? (
                  <Spinner />
                ) : (
                  <button
                    type="button"
                    onClick={handlePlanDelete}
                    className="mc-btn primary"
                  >
                    Delete
                  </button>
                )}
              </div>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ChangeIbPlanForm;
