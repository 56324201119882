import React, { useState } from "react";
import { List, Item, Anchor, Button } from "../elements";
import _ from "lodash";

export default function MenuItem({ item, activeTab, setActiveTab }) {
  const active = window?.location.href?.includes(item.href) ?? false;

  const isActiveTab = () => {
    if (active) return true;

    if (activeTab === item.text) return true;

    if (item.submenu) {
      const activeSub = _.find(item.submenu, (_i) => {
        return window?.location.href?.includes(_i.href) ?? false;
      });

      if (activeSub) return true;
    }

    return false;
  };

  return (
    <Item className={`mc-sidebar-menu-item ${isActiveTab() ? "active" : ""}`}>
      {item.submenu ? (
        <>
          <Button
            icon={item.icon}
            text={item.text}
            badge={item.badge}
            arrow="expand_more"
            className="mc-sidebar-menu-btn"
            onClick={() => {
              if (item?.text === activeTab) {
                setActiveTab("");
              } else {
                setActiveTab(item.text);
              }
            }}
          />
          <List className="mc-sidebar-dropdown-list">
            {item.submenu.map((item, index) => (
              <Item key={index} className="mc-sidebar-dropdown-item">
                <Anchor
                  href={item.href}
                  className="mc-sidebar-dropdown-link"
                  onClick={item?.onClick}
                >
                  {item.text}
                </Anchor>
              </Item>
            ))}
          </List>
        </>
      ) : (
        <Anchor
          onClick={item?.onClick}
          href={item.href}
          icon={item.icon}
          text={item.text}
          badge={item.badge}
          className="mc-sidebar-menu-btn"
        />
      )}
    </Item>
  );
}
