import { Grid, Spinner } from "@chakra-ui/react";
import { useFormik, getIn } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import _ from "lodash";
import { LabelField } from "../../../components/fields";
import { Box, Icon, Label } from "../../../components/elements";

import {
  addNewUser,
  adminCreateDepositWithdrawalRequest,
  adminCreateTradingAccountService,
  changeUserActivePlanService
} from "../../../services/admin.service";
import { useShowToast } from "../../../hooks";
import CustomReactSelect from "../../../components/fields/CustomReactSelect";
import { TransactionFlowEnum } from "../../../config/enum";

const thead = ["Level", "Commission Per Lot", ""];
const SignupSchema = Yup.object().shape({
  id: Yup.number().typeError("Required").required("Required"),
  account_id: Yup.number().typeError("Required").required("Required"),
  amount: Yup.number().typeError("Required").required("Required"),
  comments: Yup.string()
});

// accounts methods
const ChangeIbPlanForm = ({ accounts, methods, type }) => {
  const showToast = useShowToast();

  const formik = useFormik({
    initialValues: {
      id: undefined,
      account_id: 1,
      amount: 0,
      comment: ""
    },
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);

      try {
        const result = await adminCreateDepositWithdrawalRequest({
          ...values,
          type: type == "Withdraw" ? "WITHDRAW" : "DEPOSIT",
          amount: values.amount,
          comment: values.comment ?? "",
          transaction_flow: TransactionFlowEnum.DIRECT_ACCOUNT
        });

        if (result?.data?.success) {
          showToast("Success", "Request created successfully");
          actions.setSubmitting(false);
          actions.resetForm();
          return;
        }
      } catch (e) {
        console.log(e);
      }

      showToast("Error", "Something went wrong", "error");
      actions.setSubmitting(false);
    },
    validationSchema: SignupSchema
  });

  const getActiveAccount = () => {
    try {
      const acc = _.find(accounts, (a) =>
        a?.id === formik?.values?.id ? true : false
      );

      if (acc) {
        return {
          label:
            acc?.account_id +
            "  " +
            acc?.name +
            (acc?.is_live ? " (LIVE)" : " (DEMO)"),
          value: acc?.id
        };
      }
    } catch (error) {}

    return {
      label: "Select Account",
      value: undefined
    };
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid gap={4} my={4} maxWidth="500px">
          <Box>
            <Box>
              <CustomReactSelect
                label="Select Account"
                name="id"
                options={[
                  {
                    label: "Select Account",
                    value: undefined
                  },
                  ..._.map(_.sortBy(accounts, "account_id"), (_u) => {
                    return {
                      label:
                        _u?.account_id +
                        "  " +
                        _u?.name +
                        (_u?.is_live ? " (LIVE)" : " (DEMO)"),
                      value: _u?.id
                    };
                  })
                ]}
                value={getActiveAccount()}
                errorMessage={
                  formik?.errors?.id && formik?.touched?.id
                    ? formik?.errors?.id
                    : false
                }
                onChange={(e) => {
                  formik.setFieldTouched("id");
                  formik.setFieldValue("id", e?.value);
                }}
                onBlur={() => {
                  formik.setFieldTouched("id");
                }}
              />
            </Box>
          </Box>

          <LabelField
            label="Select Method"
            name="account_id"
            option={[
              {
                title: "Select Plan",
                value: undefined
              },
              ..._.map(_.sortBy(methods, "id"), (_u) => {
                return {
                  title: _u?.title,
                  value: _u?.id
                };
              })
            ]}
            value={formik?.values?.account_id}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            errorMessage={
              formik?.errors?.account_id && formik?.touched?.account_id
                ? formik?.errors?.account_id
                : false
            }
          />

          <LabelField
            label="Amount"
            type="number"
            name="amount"
            value={formik?.values?.amount}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            errorMessage={
              formik?.errors?.amount && formik?.touched?.amount
                ? formik?.errors?.amount
                : false
            }
          />

          <LabelField
            label="Comment"
            type="text"
            name="comment"
            value={formik?.values?.comment}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            errorMessage={
              formik?.errors?.comment && formik?.touched?.comment
                ? formik?.errors?.comment
                : false
            }
          />

          <Box style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ marginLeft: "auto" }}></div>

            <button
              type="submit"
              className="mc-btn primary"
              style={{ minHeight: "42px" }}
            >
              {formik?.isSubmitting ? <Spinner /> : type}
            </button>
          </Box>
        </Grid>
      </form>
    </>
  );
};

export default ChangeIbPlanForm;
