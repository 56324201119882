import React, { useEffect, useState } from "react";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import jsonData from "../../data/master/ecommerce.json";
import { Box, Item, Anchor, Text } from "../../components/elements";
import {
  EcommerceCard,
  SalesCard,
  ProductsCard,
  RevenueCard,
  ClientsCard,
  ActivityCard,
  OrdersCard
} from "../../components/cards";
import { Flex, Skeleton, textDecoration } from "@chakra-ui/react";
import useSession from "../../hooks/useSession";
import { useQuery } from "react-query";
import { dashboardService, userDashboardService } from "../../services/client";
import _ from "lodash";
import AIFormModal from "./AIForm";
import {
  CONFIG_KEYS,
  getConfig,
  setConfig
} from "../../services/config.service";
import { useShowAIForm } from "../../hooks/useShowAIForm";
import { Row, Col, Tab, Tabs, Form } from "react-bootstrap";
import { CardLayout, TabCard } from "../../components/cards";
import { Table, Tbody, Td, Tr } from "../../components/elements/Table";
import { text } from "d3";

export default function Ecommerce() {
  const [user, isLoading, setUser] = useSession();

  return (
    <PageLayout
      title="Dashboard"
      breadcrumb={[{ title: "Dashboard", path: "/" }]}
    >
      {user ? <DashboardContainer user={user} /> : null}
    </PageLayout>
  );
}

const AccountRow = () => {
  return (
    <>
      <Flex direction="row" justify="space-evenly">
        <Text>loader</Text>
      </Flex>
    </>
  );
};

const DashboardLoader = () => {
  return (
    <>
      <Row>
        <Col xl={12}>
          <Skeleton minHeight="250px" />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xl={3}>
          <Skeleton minHeight="250px" />
        </Col>
        <Col xl={3}>
          <Skeleton minHeight="250px" />
        </Col>
        <Col xl={3}>
          <Skeleton minHeight="250px" />
        </Col>

        <Col xl={3}>
          <Skeleton minHeight="250px" />
        </Col>
      </Row>

      <br />

      <Row>
        <Col xl={8}>
          <Skeleton minHeight="250px" />
        </Col>
        <Col xl={4}>
          <Skeleton minHeight="250px" />
        </Col>
      </Row>
    </>
  );
};

const DashboardContainer = ({ user }) => {
  const { isLoading, error, data } = useQuery(["clientDashboard"], () =>
    userDashboardService()
  );

  const [openAIModal, setOpenAIModal] = useState(undefined);
  const aiHook = useShowAIForm();

  const accounts = data?.data?.data?._dash?.users?.data ?? [];

  useEffect(() => {
    if (aiHook?.isOpen === undefined) {
      let modalShown = getConfig(CONFIG_KEYS.AI_FORM_MODAL_SHOWN);
      if (!modalShown) {
        if (accounts?.length) {
          let neverShownModal = getConfig(
            CONFIG_KEYS.NEVER_SHOW_AI_FORM_MODAL_SHOWN
          );
          if (!neverShownModal) {
            setConfig(CONFIG_KEYS.AI_FORM_MODAL_SHOWN, true);
            aiHook?.onOpen(true);
          }
        }
      }
    }
  }, [accounts, isLoading, aiHook?.isOpen]);

  if (isLoading || error) return <DashboardLoader />;

  const total = data?.data?.data?.total?.length
    ? data?.data?.data?.total[0]
    : null;

  const revenue = data?.data?.data?.revenue?.length
    ? data?.data?.data?.revenue[0]
    : null;

  const totalMargin = () => {
    let num = 0;

    _.forEach(accounts, (_u) => {
      if (!_u?.is_live) return;
      num += _u?.margin;
    });

    return num;
  };
  const totalEquity = () => {
    let num = 0;

    _.forEach(accounts, (_u) => {
      if (!_u?.is_live) return;
      num += _u?.equity;
    });

    return num;
  };
  const totalBalance = () => {
    let num = 0;

    _.forEach(accounts, (_u) => {
      if (!_u?.is_live) return;

      num += _u?.balance;
    });

    return num;
  };

  // equity balance used_margin margin
  const getValue = (key) => {
    switch (key) {
      case "equity":
        return totalEquity();

      case "margin":
        return totalMargin();

      case "balance":
        return totalBalance();

      case "accounts":
        return accounts?.length ?? 0;

      default:
        return 0;
    }
  };

  const handleCloseAIModalForm = () => {
    aiHook?.onClose(false);
  };

  const liveAccounts = _.filter(accounts, (acc) =>
    acc?.is_live == 1 ? true : false
  );

  const demoAccounts = _.filter(accounts, (acc) =>
    acc?.is_live == 0 ? true : false
  );

  const formatNum = (val) => {
    if (!isNaN(val)) {
      return val?.toFixed(2);
    }

    return "";
  };

  return (
    <>
      <Row>
        <Col sm={12} xl={7}>
          <div>
            <div className="mc-card-header">
              <h4 className="mc-card-title">{"My Accounts"}</h4>
            </div>
            <div style={{ minHeight: "250px" }}>
              <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-3 accountsTab"
              >
                <Tab eventKey="home" title="Live Accounts" className="">
                  <Box
                    className="mc-table-responsive"
                    style={{ textAlign: "right" }}
                  >
                    <Table className="mc-table product">
                      <Tbody className="mc-table-body even">
                        {liveAccounts?.length ? (
                          <>
                            {_.map(liveAccounts, (acc) => (
                              <Tr key={acc?.login}>
                                <Td>
                                  <h5
                                    className="mc-ecommerce-card-meta"
                                    style={{
                                      color: "unset !important",
                                      fontSize: "18px",
                                      fontWeight: 600
                                    }}
                                  >
                                    {acc?.login}
                                  </h5>
                                  <Text
                                    as="span"
                                    style={{
                                      lineHeight: 1,
                                      textTransform: "uppercase"
                                    }}
                                  >
                                    LOGIN
                                  </Text>
                                </Td>

                                <Td>
                                  <h5
                                    className="mc-ecommerce-card-meta"
                                    style={{
                                      color: "unset !important",
                                      fontSize: "18px",
                                      fontWeight: 600
                                    }}
                                  >
                                    {formatNum(acc?.balance)}
                                  </h5>
                                  <Text
                                    as="span"
                                    style={{
                                      lineHeight: 1,
                                      textTransform: "uppercase"
                                    }}
                                  >
                                    BALANCE
                                  </Text>
                                </Td>
                                <Td>
                                  <h5
                                    className="mc-ecommerce-card-meta"
                                    style={{
                                      color: "unset !important",
                                      fontSize: "18px",
                                      fontWeight: 600
                                    }}
                                  >
                                    {formatNum(acc?.equity)}
                                  </h5>
                                  <Text
                                    as="span"
                                    style={{
                                      lineHeight: 1,
                                      textTransform: "uppercase"
                                    }}
                                  >
                                    EQUITY
                                  </Text>
                                </Td>

                                <Td>
                                  <h5
                                    className="mc-ecommerce-card-meta"
                                    style={{
                                      color: "unset !important",
                                      fontSize: "18px",
                                      fontWeight: 600
                                    }}
                                  >
                                    {formatNum(acc?.margin)}
                                  </h5>
                                  <Text
                                    as="span"
                                    style={{
                                      lineHeight: 1,
                                      textTransform: "uppercase"
                                    }}
                                  >
                                    MARGIN
                                  </Text>
                                </Td>
                              </Tr>
                            ))}
                          </>
                        ) : (
                          <Tr>
                            <Td colSpan={4} style={{ textAlign: "center" }}>
                              <div className="my-4 py-4">
                                You do not have any live accounts,{" "}
                                <Anchor
                                  style={{ textDecoration: "underline" }}
                                  href="/accounts/create"
                                >
                                  Click here
                                </Anchor>{" "}
                                to create a new one
                              </div>
                            </Td>
                          </Tr>
                        )}
                      </Tbody>
                    </Table>
                  </Box>
                </Tab>
                <Tab eventKey="profile" title="Demo Accounts">
                  <Box
                    className="mc-table-responsive"
                    style={{ textAlign: "right" }}
                  >
                    <Table className="mc-table product">
                      <Tbody className="mc-table-body even">
                        {demoAccounts?.length ? (
                          <>
                            {_.map(demoAccounts, (acc) => (
                              <Tr key={acc?.login}>
                                <Td>
                                  <h5
                                    className="mc-ecommerce-card-meta"
                                    style={{
                                      color: "unset !important",
                                      fontSize: "18px",
                                      fontWeight: 600
                                    }}
                                  >
                                    {acc?.login}
                                  </h5>
                                  <Text
                                    as="span"
                                    style={{
                                      lineHeight: 1,
                                      textTransform: "uppercase"
                                    }}
                                  >
                                    LOGIN
                                  </Text>
                                </Td>

                                <Td>
                                  <h5
                                    className="mc-ecommerce-card-meta"
                                    style={{
                                      color: "unset !important",
                                      fontSize: "18px",
                                      fontWeight: 600
                                    }}
                                  >
                                    {formatNum(acc?.balance)}
                                  </h5>
                                  <Text
                                    as="span"
                                    style={{
                                      lineHeight: 1,
                                      textTransform: "uppercase"
                                    }}
                                  >
                                    BALANCE
                                  </Text>
                                </Td>
                                <Td>
                                  <h5
                                    className="mc-ecommerce-card-meta"
                                    style={{
                                      color: "unset !important",
                                      fontSize: "18px",
                                      fontWeight: 600
                                    }}
                                  >
                                    {formatNum(acc?.equity)}
                                  </h5>
                                  <Text
                                    as="span"
                                    style={{
                                      lineHeight: 1,
                                      textTransform: "uppercase"
                                    }}
                                  >
                                    EQUITY
                                  </Text>
                                </Td>

                                <Td>
                                  <h5
                                    className="mc-ecommerce-card-meta"
                                    style={{
                                      color: "unset !important",
                                      fontSize: "18px",
                                      fontWeight: 600
                                    }}
                                  >
                                    {formatNum(acc?.margin)}
                                  </h5>
                                  <Text
                                    as="span"
                                    style={{
                                      lineHeight: 1,
                                      textTransform: "uppercase"
                                    }}
                                  >
                                    MARGIN
                                  </Text>
                                </Td>
                              </Tr>
                            ))}
                          </>
                        ) : (
                          <Tr>
                            <Td colSpan={4} style={{ textAlign: "center" }}>
                              <div className="my-4 py-4">
                                You do not have any demo accounts,{" "}
                                <Anchor
                                  style={{ textDecoration: "underline" }}
                                  href="/accounts/create"
                                >
                                  Click here
                                </Anchor>{" "}
                                to create a new one
                              </div>
                            </Td>
                          </Tr>
                        )}
                      </Tbody>
                    </Table>
                  </Box>
                </Tab>
              </Tabs>
            </div>
          </div>

          <br />

          <RevenueCard
            title={jsonData?.revenue.title}
            field={jsonData?.revenue.field}
            report={jsonData?.revenue.report}
            chart={
              _.sortBy(revenue, (item) => {
                const [month, year] = item.month_year.split("-");
                return new Date(year, month - 1); // Month is 0-indexed in JavaScript Date
              }) ?? []
            }
          />
        </Col>

        <Col sm={12} xl={5}>
          <div>
            <div className="mc-card-header">
              <h4 className="mc-card-title">{"Overview"}</h4>
            </div>
            <br />

            <Row>
              {jsonData?.heros?.map((item, index) => (
                <Col key={index} sm={12} xl={6}>
                  <EcommerceCard
                    icon={item.icon}
                    trend={item.trend}
                    title={item.title}
                    number={getValue(item?.key).toFixed(2)}
                    variant={item.variant}
                    percent={0}
                    compare={0}
                    dotsMenu={item.dotsMenu}
                  />
                </Col>
              ))}

              <Col>
                <EcommerceCard
                  icon={"shopping_bag"}
                  trend={"trending_up"}
                  title={"Wallet Balance"}
                  number={data?.data?.data?.walletBalance ?? 0}
                  variant={"blue"}
                  percent={0}
                  compare={0}
                  dotsMenu={null}
                />
              </Col>
            </Row>

            <br />
            <br />

            <Row>
              <Col>
                <OrdersCard
                  title={"My Transactions"}
                  dotsMenu={jsonData?.orders.dotsMenu}
                  items={[
                    {
                      name: "deposit",
                      value: total?.deposit_sum ?? 0,
                      color: "purple",
                      icon: "pending"
                    },
                    {
                      name: "withdrawal",
                      value: total?.withdraw_sum ?? 0,
                      color: "blue",
                      icon: "add_circle"
                    }
                  ]}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12} xl={12}></Col>
        {/* <Col xs={12} xl={4}>
          <SalesCard
            title={jsonData?.sales.title}
            amount={getValue("balance")}
            percent={0}
            trendIcon={jsonData?.sales.trendIcon}
            dotsMenu={jsonData?.sales.dotsMenu}
            compare={0}
            chart={jsonData?.sales.chart}
          />
        </Col> */}
        <Col xl={12}></Col>
        <Col xl={8}></Col>
        <Col xl={4}></Col>
      </Row>

      <AIFormModal
        isOpen={aiHook?.isOpen}
        onClose={handleCloseAIModalForm}
        name={user?.name ?? ""}
        clientId={user?.id ?? ""}
        accounts={accounts ?? []}
      />
    </>
  );
};
