import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb, Pagination } from "../../../components";
import PageLayout from "../../../layouts/PageLayout";
import { Box, Item, Anchor, Button } from "../../../components/elements";
import { CardLayout } from "../../../components/cards";
import {
  Skeleton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Flex,
  Spinner
} from "@chakra-ui/react";
import DepositTable from "./table";
import { useQuery } from "react-query";
import {
  approveRejectRequestService,
  getAllTradeCommissionsService,
  getRequestsByType
} from "../../../services/admin.service";
import { useShowToast } from "../../../hooks";
import SearchUserField from "../../../components/admin/SearchUser";
import formatDateHelper from "../../../utils/formatDate";
import downloadFile from "../../../utils/downloadFile";
import convertJsonToCsv from "../../../utils/convertJsonToCsv";
import _ from "lodash";
import { LabelField } from "../../../components/fields";
import CustomReactSelect from "../../../components/fields/CustomReactSelect";
import moment from "moment";
import DateRangePicker from "../../../components/DateRangePicker";

export default function AdminIBCommission() {
  return (
    <PageLayout
      title="IB Trade Commissions"
      breadcrumb={[
        {
          title: "Home",
          path: "/ib/dashboard"
        },
        {
          title: "IB Trade Commissions"
        }
      ]}
    >
      <Row>
        <DepositsPaymentPage />
      </Row>
    </PageLayout>
  );
}

const Loader = () => {
  return <Skeleton minHeight="500px" />;
};

const DepositsPaymentPage = () => {
  const [type, setType] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [modifyRequest, setModifyRequest] = useState(null);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const showToast = useShowToast();
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [isDownloading, setIsDownloading] = useState(false);
  const [forceSearch, setForceSearch] = useState(0.01);
  const [name, setName] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [pos, setPos] = useState("");
  const [deal, setDeal] = useState("");
  const [finalState, setFinalState] = useState({
    name,
    selectedUser,
    deal,
    pos
    // type,
    // from: undefined,
    // to: undefined,
  });

  useEffect(() => {
    setFinalState({
      selectedUser,
      pos,
      deal
      // type,
      // from:
      //   dateRange?.length == 2 && dateRange[0]
      //     ? moment(dateRange[0]).format("YYYY-MM-DD")
      //     : undefined,
      // to:
      //   dateRange?.length == 2 && dateRange[1]
      //     ? moment(dateRange[1]).format("YYYY-MM-DD")
      //     : undefined,
    });
  }, [forceSearch]);

  useEffect(() => {
    setCurrentPage(1);
  }, [type, selectedUser]);

  // getAllTradeCommissionsService
  const { isLoading, error, data, refetch } = useQuery(
    ["admin-all-commissions", forceSearch, currentPage, finalState],
    () =>
      getAllTradeCommissionsService(
        currentPage,
        finalState.selectedUser,
        finalState.deal,
        finalState.pos
      )
  );

  const total = data?.data?.data?.walletRequest?.total;
  const requests = data?.data?.data?.walletRequest?.data ?? [];
  const last_page = data?.data?.data?.walletRequest?.last_page ?? 1;

  const handleChangeStatus = async () => {
    setIsUpdatingStatus(true);

    try {
      const result = await approveRejectRequestService(
        modifyRequest?.req?.id,
        modifyRequest?.status === "accept" ? "ACCEPTED" : "REJECTED"
      );

      if (result?.data?.success) {
        await refetch();
        showToast("Success", "Request Updated Successfully");
        setIsUpdatingStatus(false);
        setModifyRequest(null);
        return;
      } else {
      }
    } catch (error) {
      console.log(error);
    }
    showToast("Error", "Something went wrong", "error");
    setIsUpdatingStatus(false);
  };

  const handleCSVDownload = async () => {
    setIsDownloading(true);

    try {
      const result = await getRequestsByType(
        currentPage,
        finalState.type,
        "ACCEPTED",
        finalState.selectedUser,
        true,
        finalState.name,
        "direct_wallet,direct_account",
        finalState?.from,
        finalState?.to
      );

      // ID, ACCOUNT,
      const formattedData = _.map(result?.data?.data?.walletRequest, (_r) => {
        const _request = _r;

        return {
          ID: _request?.id,
          ACCOUNT: _request?.account?.account_id,
          NAME: _request?.created_by?.name ?? "",
          EMAIL: _request?.created_by?.email ?? "",
          CREATED_AT: formatDateHelper(_request?.created_at, true),
          TYPE: _request?.transactedWith?.title,
          AMOUNT: _request?.amount,
          ACTION: _request?.type,
          STATUS: _request?.status,
          REFERENCE: _request?.reference
        };
      });

      //      console.log()
      downloadFile({
        data: convertJsonToCsv(formattedData, true),
        fileName: "transactions.csv",
        fileType: "text/csv"
      });
    } catch (error) {
      console.error("Error downloading file:", error);
    }

    setIsDownloading(false);
  };

  // return <>{JSON.stringify(data?.data?.data?.Commission)}</>

  return (
    <>
      <Col xl={12}>
        <>
          <Row>
            {window?.location?.href?.includes("/admin") ? (
              <Col xl={3}>
                <SearchUserField
                  userId={selectedUser}
                  onChangeUserId={setSelectedUser}
                />
              </Col>
            ) : null}

            <Col xl={3}>
              <LabelField
                type="number"
                label="Search Position"
                value={pos}
                onChange={(e) => {
                  setPos(e?.target?.value);
                }}
              />
            </Col>

            <Col xl={3}>
              <LabelField
                type="number"
                label="Search Deal"
                value={deal}
                onChange={(e) => {
                  setDeal(e?.target?.value);
                }}
              />
            </Col>

            <Col xl={4}> </Col>

            <Col xl={2}>
              <div
                style={{
                  display: "inline-flex",
                  marginTop: "24px",
                  textAlign: "right",
                  flexDirection: "column",
                  width: "100%",
                  maxWidth: "120px"
                }}
              >
                <Button
                  onClick={() => {
                    setForceSearch((_v) => _v + 0.01);
                  }}
                  className="mc-btn primary"
                >
                  Search
                </Button>
              </div>
            </Col>
          </Row>
          <br />

          {isLoading ? (
            <Loader />
          ) : (
            <>
              <DepositTable
                requests={data?.data?.data?.Commission?.data ?? []}
                onChangeStatus={(_val) => {
                  setModifyRequest(_val);
                }}
              />
              <br />
              <Pagination
                currentPage={currentPage}
                lastPage={data?.data?.data?.Commission?.last_page}
                setCurrentPage={setCurrentPage}
              />
            </>
          )}
        </>
      </Col>
      <ModifyRequestAlert
        request={modifyRequest}
        setRequest={setModifyRequest}
        isUpdatingStatus={isUpdatingStatus}
        onRequestChange={handleChangeStatus}
      />
    </>
  );
};

const ModifyRequestAlert = ({
  request,
  setRequest,
  isUpdatingStatus,
  onRequestChange
}) => {
  const cancelRef = useRef();

  return (
    <AlertDialog
      isOpen={request ? true : false}
      leastDestructiveRef={cancelRef}
      onClose={() => {
        setRequest(null);
      }}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            CONFIRM {request?.req?.type} ?
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure, You want {request?.status} request made by &nbsp;
            <b>{request?.req?.created_by?.name}</b> for{" "}
            <b>{request?.req?.amount} USDT</b> ?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Flex>
              <div style={{ marginRight: "4px" }}>
                {!isUpdatingStatus ? (
                  <button
                    onClick={() => {
                      setRequest(null);
                    }}
                    className="mc-btn gray"
                    style={{ textTransform: "uppercase", minHeight: "32px" }}
                  >
                    Cancel
                  </button>
                ) : (
                  <></>
                )}
              </div>

              <div style={{ marginRight: "4px" }}>
                <button
                  onClick={() => {
                    onRequestChange();
                  }}
                  className="mc-btn green"
                  style={{ textTransform: "uppercase", minHeight: "32px" }}
                >
                  {isUpdatingStatus ? <Spinner /> : request?.status}
                </button>
              </div>
            </Flex>

            {/* <Button
              ref={cancelRef}
              onClick={() => {
                setRequest(null)
              }}
            >
              Cancel
            </Button>
            <Button
              className="mc-bn red"
              onClick={() => {
                setRequest(null)
              }}
              ml={3}
            >
              Delete
            </Button> */}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
