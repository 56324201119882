import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Tab, Tabs, Form } from "react-bootstrap";
import { Breadcrumb, Pagination } from "../../../components";
import PageLayout from "../../../layouts/PageLayout";
import data from "../../../data/master/admin/payments.json";
import { Box, Item, Anchor, Button } from "../../../components/elements";
import { CardLayout, TabCard } from "../../../components/cards";
import {
  EcommerceCard,
  SalesCard,
  ProductsCard,
  RevenueCard,
  ActivityCard,
  OrdersCard
} from "../../../components/cards";
import ClientsTable from "../../../components/admin/Clients";
import AdminAccountsPage from "../../../components/admin/Accounts";
import PaymentsPage from "../../../components/admin/Payments";
import {
  Skeleton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Spinner,
  useDisclosure
} from "@chakra-ui/react";
import { LabelField } from "../../../components/fields";
import DepositTable from "./table";
import { useQuery } from "react-query";
import {
  addPermissionToUserService,
  approveRejectRequestService,
  deleteAdminUserService,
  deletePermissionFromUserService,
  getAdminUsersAndPermissions,
  getAllIBPlans,
  getAllIBRequestsService,
  getAllPermissions,
  getAllUsersServices,
  getRequestsByType,
  makeUserIbService
} from "../../../services/admin.service";
import { useShowToast } from "../../../hooks";
import _ from "lodash";
import SearchUserField from "../../../components/admin/SearchUser";
import downloadFile from "../../../utils/downloadFile";
import convertJsonToCsv from "../../../utils/convertJsonToCsv";
import AddUserModal from "./add-user-modal";

export default function AdminManageRoles() {
  return (
    <PageLayout
      title="Manage Roles"
      breadcrumb={[
        { title: "Home", path: "/admin/dashboard" },
        { title: "Manage Roles" }
      ]}
    >
      <Row>
        <DepositsPaymentPage />
      </Row>
    </PageLayout>
  );
}

const Loader = () => {
  return <Skeleton minHeight="500px" />;
};

const DepositsPaymentPage = () => {
  const [type, setType] = useState("PENDING");
  const [currentPage, setCurrentPage] = useState(1);
  const [modifyRequest, setModifyRequest] = useState(null);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [isDownloading, setIsDownloading] = useState(false);
  const [forceSearch, setForceSearch] = useState(0.01);
  const addUserDisclosure = useDisclosure();
  const [revokeUser, setRevokeUser] = useState(null);
  const [allUsers, setUsers] = useState([]);

  const showToast = useShowToast();

  const { isLoading, error, data, refetch } = useQuery(
    ["admin-users", currentPage, forceSearch],
    () => getAdminUsersAndPermissions()
  );

  const getAllPermissionsData = useQuery(["all-permissions"], () =>
    getAllPermissions()
  );

  useEffect(() => {
    if (currentPage != 1) {
      setCurrentPage(1);
    }
  }, [type]);

  const total = data?.data?.data?.requests?.total;
  const users = data?.data?.data?.users?.data ?? [];
  const allPermissions =
    getAllPermissionsData?.data?.data?.data?.permissions?.data ?? [];

  const last_page = data?.data?.data?.ib_plan_master?.last_page ?? 1;

  useEffect(() => {
    setUsers(users);
  }, [users, isLoading]);

  const handleAddPermission = async (user_id, permission_id) => {
    // add manually
    const permission = _.find(allPermissions, (_p) =>
      _p?.id === parseInt(permission_id) ? true : false
    );

    setUsers(
      _.map(allUsers, (_u) => {
        return {
          ..._u,
          permissions:
            _u?.id === user_id
              ? [..._u?.permissions, permission]
              : _u?.permissions
        };
      })
    );

    try {
      await addPermissionToUserService({
        permission_id: permission_id,
        model_id: user_id,
        model_type: "User"
      });
      //await refetch()
    } catch (error) {}
  };

  const handleDeletePermission = async (permission_id, user_id) => {
    // add manually
    const permission = _.find(allPermissions, (_p) =>
      _p?.id === parseInt(permission_id) ? true : false
    );

    setUsers(
      _.map(allUsers, (_u) => {
        return {
          ..._u,
          permissions:
            _u?.id != user_id
              ? _u?.permissions
              : _.filter(_u?.permissions, (p) =>
                  p?.id === permission_id ? false : true
                )
        };
      })
    );

    try {
      await deletePermissionFromUserService({
        permission_id: permission_id,
        model_id: user_id
      });
    } catch (error) {}
  };

  if (isLoading) <Skeleton minHeight="500px" width="100%" />;

  return (
    <>
      <Col xl={12}>
        <>
          <Row>
            <Col xl={3}></Col>

            <Col xl={3}></Col>

            <Col xl={3}> </Col>

            <Col xl={3}>
              <div
                style={{
                  display: "flex",
                  marginTop: "24px",
                  textAlign: "right",
                  justifyContent: "flex-end"
                }}
              >
                <Button
                  onClick={() => {
                    addUserDisclosure.onOpen();
                  }}
                  className=" ml-auto mc-btn primary"
                >
                  Add User
                </Button>
              </div>
            </Col>
          </Row>

          <br />

          {isLoading ? (
            <Loader />
          ) : (
            <>
              <DepositTable
                requests={allUsers ?? []}
                onChangeStatus={(_val) => {
                  setModifyRequest(_val);
                }}
                setRevokeUser={setRevokeUser}
                allPermissions={allPermissions}
                handleAddPermission={handleAddPermission}
                handleDeletePermission={handleDeletePermission}
              />
              <br />
              <Pagination
                lastPage={last_page}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </>
          )}
        </>
      </Col>
      <ModifyRequestAlert
        request={revokeUser}
        setRequest={setRevokeUser}
        refetch={refetch}
      />

      <AddUserModal {...addUserDisclosure} refetch={refetch} />
    </>
  );
};

const ModifyRequestAlert = ({ request, setRequest, refetch }) => {
  const [isRevoking, setIsRevoking] = useState(false);
  const cancelRef = useRef();

  const handleRevokeUser = async () => {
    setIsRevoking(true);

    try {
      await deleteAdminUserService(request?.id);

      await refetch();
      setRequest(null);
    } catch (error) {}

    setIsRevoking(false);
  };

  return (
    <AlertDialog
      isOpen={request ? true : false}
      leastDestructiveRef={cancelRef}
      onClose={() => {
        setRequest(null);
      }}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            CONFIRM IB Request ?
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure, You want to delete {request?.name} user ?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Flex>
              <div style={{ marginRight: "4px" }}>
                <button
                  disabled={isRevoking}
                  ref={cancelRef}
                  onClick={() => {
                    if (!isRevoking) setRequest(null);
                  }}
                  className="mc-btn gray"
                  style={{ textTransform: "uppercase", minHeight: "32px" }}
                >
                  Cancel
                </button>
              </div>

              <div style={{ marginRight: "4px" }}>
                <button
                  disabled={isRevoking}
                  onClick={() => {
                    handleRevokeUser();
                  }}
                  className="mc-btn green"
                >
                  {isRevoking ? <Spinner /> : "Delete"}
                </button>
              </div>
            </Flex>

            {/* <Button
              ref={cancelRef}
              onClick={() => {
                setRequest(null)
              }}
            >
              Cancel
            </Button>
            <Button
              className="mc-bn red"
              onClick={() => {
                setRequest(null)
              }}
              ml={3}
            >
              Delete
            </Button> */}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
