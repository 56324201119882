import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Tab, Tabs, Form } from "react-bootstrap";
import { Breadcrumb, Pagination } from "../../../components";
import PageLayout from "../../../layouts/PageLayout";
import data from "../../../data/master/admin/payments.json";
import { Box, Item, Anchor } from "../../../components/elements";
import { CardLayout } from "../../../components/cards";
import { Skeleton } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getAllUsersServices } from "../../../services/admin.service";
import _ from "lodash";
import ChangeIbPlanForm from "./add-new-user-form";

export default function AdminWithdrawIBCommissionRequest({ type = "DEPOSIT" }) {
  return (
    <PageLayout title="Create Withdraw IB Commission Request">
      <Row>
        <DepositsPaymentPage type={type} />
      </Row>
    </PageLayout>
  );
}

const Loader = () => {
  return <Skeleton minHeight="500px" />;
};

const DepositsPaymentPage = ({ type }) => {
  const { isLoading, error, data, refetch } = useQuery(
    ["admin-ib-users", 1],
    () => getAllUsersServices(1, "IB")
  );

  const ibs = data?.data?.data?.users?.data ?? [];

  return (
    <>
      <Col xl={12}>
        <>{isLoading ? <Loader /> : <ChangeIbPlanForm ibs={ibs} />}</>
      </Col>
    </>
  );
};
