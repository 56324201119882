import React, { useEffect, useState } from "react";
import PageLayout from "../../../layouts/PageLayout";
import { Col, Row } from "react-bootstrap";
import { CardLayout } from "../../../components/cards";
import { Anchor, Button, Item, Text } from "../../../components/elements";
import { Breadcrumb, Pagination } from "../../../components";
import { Box, Flex } from "@chakra-ui/react";
import { LabelField, LabelTextarea } from "../../../components/fields";
import { useFormik } from "formik";
import { createSupportTicket, getSupportTickets } from "../../../services/ib";
import * as Yup from "yup";
import { useShowToast } from "../../../hooks";
import { Center, Text as TruncateText } from "@chakra-ui/react";
import * as _ from "lodash";
import { Spinner } from "@chakra-ui/react";
import { usePriority, useStatus } from "../../../utils/useHook";
import ReactPaginate from "react-paginate";
import SearchUserField from "../../../components/admin/SearchUser";

const Support = () => {
  return (
    <PageLayout
      title="Support"
      breadcrumb={[
        { title: "Home", path: "/admin/dashboard" },
        { title: "Support" }
      ]}
    >
      <SupportContainer />
    </PageLayout>
  );
};

const sortParams = [
  { key: "date_created", value: "Date Created" },
  { key: "date_modified", value: "Date Modified" }
];

const SupportContainer = () => {
  const [openForm, setOpenForm] = useState(false);
  // const [sortOrder, setSortOrder] = useState(sortParams[0]?.key);
  const [tickets, setTickets] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);

  const [lastPage, setLastPage] = useState(1);
  const [title, setTitle] = useState("");
  const [status, setStatus] = useState(null);
  const [prirority, setPriority] = useState(null);
  const [finalState, setFinalState] = useState({
    title,
    selectedUser,
    status,
    prirority
  });

  const handleSearch = () => {
    setCurrentPage(1);
    setFinalState({
      title,
      selectedUser,
      status,
      prirority
    });
  };

  // fetch support tickets
  useEffect(() => {
    async function fetchTickets() {
      setIsFetching(true);
      const result = await getSupportTickets(
        finalState.selectedUser,
        currentPage,
        finalState.prirority,
        finalState.title,
        finalState.status
      );

      if (result?.data?.success) {
        const data = result?.data?.data?.support?.data;

        if (result?.data?.data?.support?.last_page) {
          setLastPage(result?.data?.data?.support?.last_page);
        }

        setTickets(data);
      } else {
        console.log("failed to fetch!");
      }
      setIsFetching(false);
    }

    fetchTickets();
  }, [currentPage, finalState]);

  return (
    <Row>
      <Col sm={12} md={6} xl={3}>
        <LabelField
          type="text"
          label="Search by title"
          value={title}
          onChange={(e) => {
            setTitle(e?.target?.value);
          }}
        />
      </Col>
      <Col sm={12} md={6} xl={3}>
        <SearchUserField
          userId={selectedUser}
          onChangeUserId={(_val) => {
            //_val);
            setSelectedUser(_val);
          }}
        />
      </Col>
      <Col sm={12} md={6} xl={2}>
        <LabelField
          label="Status"
          option={[
            { title: "ALL", value: undefined },
            { title: "PENDING", value: "PENDING" },
            { title: "RESOLVED", value: "RESOLVED" }
          ]}
          value={status}
          onChange={(e) => {
            setStatus(e?.target?.value);
          }}
        />
      </Col>
      <Col sm={12} md={6} xl={2}>
        <LabelField
          label="Priority"
          option={[
            { title: "ALL", value: undefined },
            { title: "HIGH", value: "HIGH" },
            { title: "MEDIUM", value: "MEDIUM" },
            { title: "LOW", value: "LOW" }
          ]}
          value={prirority}
          onChange={(e) => {
            setPriority(e?.target?.value);
          }}
        />
      </Col>

      <Col sm={12} md={6} lg={2}>
        <Box pt={[4, 4, 0, 0]}>
          <br />
          {isFetching ? (
            <Spinner />
          ) : (
            <button className="mc-btn primary" onClick={handleSearch}>
              Search
            </button>
          )}
        </Box>
      </Col>

      <Col xl={12}>
        <>
          <Flex
            alignItems={"center"}
            marginBottom={"20px"}
            justifyContent={"space-between"}
          >
            <Box>
              {/* <CardHeader
                title={`${openForm ? "Create new ticket" : "Open or Pending"}`}
                style={{ margin: 0, marginBottom: "-5px" }}
              />
              {!openForm && (
                <Text as="small">
                  Sorted by
                  <select
                    style={{ fontWeight: "bold" }}
                    value={sortOrder}
                    onChange={(e) => setSortOrder(e.target.value)}
                  >
                    {sortParams?.map((item, index) => (
                      <option key={item.key} value={item.key}>
                        {item.value}
                      </option>
                    ))}
                  </select>
                </Text>
              )} */}
            </Box>
          </Flex>
          {openForm ? (
            <CreateNewTicket
              setOpenForm={setOpenForm}
              setTickets={setTickets}
            />
          ) : (
            <>
              {isFetching ? (
                <Center marginTop="20px">
                  <Spinner size="xl" />
                </Center>
              ) : !tickets.length ? (
                <Text>There are no available tickets!</Text>
              ) : (
                tickets?.map((item, index) => (
                  <CardBox key={index} data={item} />
                ))
              )}
            </>
          )}

          <br />
          <div
            style={{
              textAlign: "right",
              display: "flex"
            }}
          >
            <div style={{ flex: 1 }}></div>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={(e) => {
                setCurrentPage(e?.selected + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={lastPage}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
            />
          </div>
        </>
      </Col>
    </Row>
  );
};

const CardBox = ({ data }) => {
  const [dateString, setDateString] = useState("");
  const status = useStatus(data?.status);
  const priority = usePriority(data?.priority);

  useEffect(() => {
    const createdDate = new Date(data.created_at);
    const dateString = createdDate.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric"
    });
    setDateString(dateString);
  }, []);

  return (
    <Box
      mb={2}
      p={4}
      border="1px solid"
      borderColor="lightgray"
      borderRadius="5px"
      _hover={{ cursor: "pointer", backgroundColor: "#f1f0f0" }}
      onClick={() => (window.location.href = `/admin/support/${data.id}`)}
    >
      <Flex
        alignItems={["unset", "unset", "center", "center"]}
        gap={8}
        direction={["column", "column", "row", "row"]}
        wrap="wrap"
      >
        <Flex align="center">
          <div
            style={{
              width: "12px",
              height: "12px",
              minWidth: "12px",
              minHeight: "12px",
              backgroundColor: status.color,
              borderRadius: "50%"
            }}
            className="mr-4"
          ></div>
          &emsp;
          <Flex flexDirection="column" style={{ minWidth: "100px" }}>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                textWrap: "nowrap",
                color: status.color
              }}
            >
              {status.value}
            </Text>
            <Text as="small" style={{ color: "gray" }}>
              {dateString}
            </Text>{" "}
          </Flex>
        </Flex>

        <Box style={{ flex: 1 }}>
          <TruncateText as={"b"} color="#2E2E2E">
            {data.title}
          </TruncateText>
          <TruncateText as="small" noOfLines={[3, 3, 2, 1]}>
            {data.support_text}
          </TruncateText>
        </Box>

        <div>
          <Text as="small" style={{ color: "gray" }}>
            by {data?.name}
          </Text>{" "}
        </div>
        <div style={{ width: "100px" }}>
          <div>
            <Box
              style={{
                padding: "1px 10px",
                border: `2px solid ${priority.color}`,
                borderRadius: "10px",
                background: priority.color,
                color: "white",
                fontWeight: "500",
                textAlign: "center",
                textTransform: "uppercase",
                fontSize: "12px"
              }}
            >
              {priority.value}
            </Box>
          </div>
        </div>
      </Flex>
    </Box>
  );
};

const ticketSchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  description: Yup.string().required("Description is required!"),
  priority: Yup.string().required("Please select a priority!")
});

const CreateNewTicket = ({ setOpenForm, setTickets }) => {
  const showToast = useShowToast();

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      priority: ""
    },
    enableReinitialize: true,
    validationSchema: ticketSchema,
    onSubmit: (values, actions) => {
      handleTicket(values, actions);
    }
  });

  const handleTicket = async (values, actions) => {
    actions.setSubmitting(true);

    try {
      const result = await createSupportTicket(
        values?.title,
        values?.description,
        values?.priority
      );

      if (result?.data?.success) {
        setTickets((data) => [result?.data?.data?.support, ...data]);
        showToast("Success", "Deposit request created successfully");
        actions?.resetForm();
        actions.setSubmitting(false);
        setOpenForm(false);
        return;
      }
    } catch (error) {}

    showToast("Error", "Something went wrong", "error");
    actions.setSubmitting(false);
  };

  return (
    <>
      <Row>
        <form onSubmit={formik.handleSubmit}>
          <LabelField
            label="Title"
            type="text"
            fieldSize="w-100 h-md"
            name={"title"}
            value={formik?.values.title}
            onChange={formik?.handleChange}
            errorMessage={
              formik?.errors?.title && formik?.touched?.title
                ? formik?.errors?.title
                : false
            }
          />
          <br />
          <LabelField
            label="Priority"
            fieldSize="w-100 h-md"
            name={"priority"}
            option={[
              {
                title: "Select the priority!",
                value: ""
              },
              {
                title: "Low",
                value: "low"
              },
              {
                title: "Medium",
                value: "medium"
              },
              {
                title: "High",
                value: "high"
              }
            ]}
            value={formik?.values.priority}
            onChange={formik?.handleChange}
            errorMessage={
              formik?.errors?.priority && formik?.touched?.priority
                ? formik?.errors?.priority
                : false
            }
          />
          <br />
          <LabelTextarea
            label="Description"
            fieldSize="w-100"
            name={"description"}
            value={formik?.values.description}
            onChange={formik?.handleChange}
            errorMessage={
              formik?.errors?.description && formik?.touched?.description
                ? formik?.errors?.description
                : false
            }
          />
          <br />
          {/* <LabelField
            label="Attach a file"
            type="file"
            fieldSize="w-100 h-md"
            accept=".jpg, .jpeg, .png, .gif, .pdf"
            ref={imageUploadref}
            onChange={(e) => {
              if (e?.target?.files?.length) {
                setScreenShot(e?.target?.files[0]);
              } else {
                setScreenShot(null);
              }
            }}
          /> */}

          <Flex gap={5} marginTop={10}>
            <Button
              className="mc-btn red h-sm"
              onClick={() => setOpenForm(false)}
              disabled={formik?.isSubmitting}
              style={{
                filter: `contrast(${formik?.isSubmitting ? "50%" : 1})`
              }}
            >
              Cancel
            </Button>

            <button
              type="button"
              className="mc-btn primary h-sm"
              disabled={formik?.isSubmitting}
              onClick={(e) => {
                e.preventDefault();
                formik.submitForm();
              }}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div>
                {formik?.isSubmitting ? <Spinner size="xs" /> : "Submit"}
              </div>
            </button>
          </Flex>
        </form>
      </Row>
    </>
  );
};

export default Support;
