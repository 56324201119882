import React from "react";
import Main from "./Mian";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import { DrawerProvider } from "../context/Drawer";
import { WithAuth } from "../hoc";
import { Box } from "@chakra-ui/react";
import { Breadcrumb as CBreadcrumb } from "../components";
import { Anchor, Item } from "../components/elements";
import _ from "lodash";

export default function PageLayout({ children, title, desc, breadcrumb }) {
  const location = useLocation();

  return (
    <WithAuth>
      <DrawerProvider>
        <Header />
        <Sidebar />
        <Main>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              minHeight: "calc(100vh - 104px)",
              width: "100%"
            }}
          >
            {title ? (
              <Box className="mc-card">
                <CBreadcrumb title={title} subtitle={desc ?? ""}>
                  {breadcrumb?.length
                    ? _.map(breadcrumb, (item) => {
                        return (
                          <Item
                            key={item?.title}
                            className="mc-breadcrumb-item"
                          >
                            {item.path ? (
                              <Anchor
                                className="mc-breadcrumb-link"
                                href={item.path}
                              >
                                {item.title}
                              </Anchor>
                            ) : (
                              item.title
                            )}
                          </Item>
                        );
                      })
                    : null}
                </CBreadcrumb>
              </Box>
            ) : null}

            <Box flex={1} className="mc-card">
              <>{children}</>
            </Box>
            {location.pathname !== "/message" ? <Footer /> : ""}
          </Box>
        </Main>
      </DrawerProvider>
    </WithAuth>
  );
}
