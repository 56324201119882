import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Tab, Tabs, Form } from "react-bootstrap";
import { Breadcrumb, Pagination } from "../../../components";
import PageLayout from "../../../layouts/PageLayout";
import data from "../../../data/master/admin/payments.json";
import { Box, Item, Anchor, Button } from "../../../components/elements";
import { CardLayout, TabCard } from "../../../components/cards";
import {
  EcommerceCard,
  SalesCard,
  ProductsCard,
  RevenueCard,
  ActivityCard,
  OrdersCard
} from "../../../components/cards";
import ClientsTable from "../../../components/admin/Clients";
import AdminAccountsPage from "../../../components/admin/Accounts";
import PaymentsPage from "../../../components/admin/Payments";
import {
  Skeleton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Spinner
} from "@chakra-ui/react";
import { LabelField } from "../../../components/fields";
import DepositTable from "./table";
import { useQuery } from "react-query";
import {
  approveRejectRequestService,
  getAllIBPlans,
  getAllIBRequestsService,
  getAllUsersServices,
  getIBWithdrawalRequestsService,
  getRequestsByType,
  makeUserIbService,
  updateIBWithdrawRequest
} from "../../../services/admin.service";
import { useShowToast } from "../../../hooks";
import _ from "lodash";
import SearchUserField from "../../../components/admin/SearchUser";
import downloadFile from "../../../utils/downloadFile";
import convertJsonToCsv from "../../../utils/convertJsonToCsv";

export default function AdminIBWithdrawRequests() {
  return (
    <PageLayout title="IB Withdraw Requests">
      <Row>
        <DepositsPaymentPage />
      </Row>
    </PageLayout>
  );
}

const Loader = () => {
  return <Skeleton minHeight="500px" />;
};

const DepositsPaymentPage = () => {
  const [type, setType] = useState("PENDING");
  const [currentPage, setCurrentPage] = useState(1);
  const [modifyRequest, setModifyRequest] = useState(null);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [isDownloading, setIsDownloading] = useState(false);
  const [forceSearch, setForceSearch] = useState(0.01);
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");

  const [finalState, setFinalState] = useState({
    name,
    selectedUser,
    type
  });

  const showToast = useShowToast();

  const { isLoading, error, data, refetch } = useQuery(
    ["ib-withdrawal-requests", currentPage, forceSearch, finalState],
    () =>
      getIBWithdrawalRequestsService(
        "WITHDRAW",
        currentPage,
        finalState.type,
        finalState.selectedUser,
        finalState.name
      )
  );

  useEffect(() => {
    setFinalState({
      name,
      selectedUser,
      type
    });
  }, [forceSearch]);

  const handleChangeStatus = async (plan_id = 0) => {
    setIsUpdatingStatus(true);

    try {
      const result = await updateIBWithdrawRequest(
        modifyRequest?.id,
        modifyRequest?.status,
        comment
      );

      console.log(result);

      if (result?.data?.success) {
        await refetch();
        showToast("Success", "Request Updated Successfully");
        setIsUpdatingStatus(false);
        setModifyRequest(null);
        setComment("");
        return;
      } else {
        console.log(result?.data?.message);
        showToast(
          "Error",
          result?.data?.message ?? "Something went wrong",
          "error"
        );
      }
    } catch (error) {
      console.log(error);
    }

    setIsUpdatingStatus(false);
  };

  const wrequests = data?.data?.data?.wrequests ?? null;

  return (
    <>
      <Col xl={12}>
        <>
          <Row>
            <Col xl={3}>
              <LabelField
                label="search by name"
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e?.target?.value);
                }}
              />
            </Col>

            <Col xl={3}>
              <LabelField
                name="Type"
                label="Select Stage"
                option={[
                  { title: "ALL", value: undefined },
                  { title: "PENDING", value: "PENDING" },
                  { title: "ACCEPTED", value: "ACCEPTED" },
                  { title: "REJECTED", value: "REJECTED" }
                ]}
                value={type}
                onChange={(e) => {
                  setType(
                    e?.target?.value === "ALL" ? undefined : e?.target?.value
                  );
                }}
              />
            </Col>

            <Col xl={3}>
              <SearchUserField
                userId={selectedUser}
                onChangeUserId={setSelectedUser}
              />
            </Col>

            <Col xl={3}>
              <div
                style={{
                  display: "flex",
                  marginTop: "24px",
                  textAlign: "right"
                }}
              >
                <div style={{ marginLeft: "auto" }}> </div>
                {/* <Button
                  onClick={() => {
                    handleCSVDownload()
                  }}
                  className="mc-btn green"
                >
                  {isDownloading ? "Downloading" : "Download"}
                </Button> */}
                &nbsp;
                <Button
                  onClick={() => {
                    setCurrentPage(1);
                    setForceSearch((_v) => _v + 0.01);
                  }}
                  className="mc-btn primary"
                >
                  Search
                </Button>
              </div>
            </Col>
          </Row>

          <br />

          {isLoading ? (
            <Loader />
          ) : (
            <>
              <DepositTable
                requests={wrequests?.data ?? []}
                onChangeStatus={(_val) => {
                  setModifyRequest(_val);
                }}
              />
              <br />
              <Pagination
                lastPage={wrequests?.last_page}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </>
          )}
        </>
      </Col>
      <ModifyRequestAlert
        request={modifyRequest}
        setRequest={setModifyRequest}
        isUpdatingStatus={isUpdatingStatus}
        onRequestChange={handleChangeStatus}
        comment={comment}
        setComment={setComment}
      />
    </>
  );
};

const ModifyRequestAlert = ({
  request,
  setRequest,
  isUpdatingStatus,
  onRequestChange,
  ibPlans,
  comment,
  setComment
}) => {
  const cancelRef = useRef();

  const disableSubmitButton = () => {
    if (isUpdatingStatus) return true;

    return false;
  };

  return (
    <AlertDialog
      isOpen={request ? true : false}
      leastDestructiveRef={cancelRef}
      onClose={() => {
        setRequest(null);
      }}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {request?.status == "ACCEPTED" ? "Accept" : "Reject"} IB Withdraw
            Request ?
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure, You want to &nbsp;
            {request?.status == "ACCEPTED" ? "accept" : "reject"} withdraw
            commission request of amount {request?.amount} for {request?.name} ?
            <br />
            <br />
            <LabelField
              type="text"
              label="Comment"
              value={comment}
              onChange={(e) => {
                setComment(e?.target?.value);
              }}
            />
          </AlertDialogBody>

          <AlertDialogFooter>
            <Flex>
              <div style={{ marginRight: "4px" }}>
                {!isUpdatingStatus ? (
                  <button
                    onClick={() => {
                      setRequest(null);
                    }}
                    className="mc-btn gray"
                    style={{ textTransform: "uppercase", minHeight: "32px" }}
                  >
                    Cancel
                  </button>
                ) : (
                  <></>
                )}
              </div>

              <div style={{ marginRight: "4px" }}>
                <button
                  disabled={disableSubmitButton()}
                  onClick={() => {
                    if (disableSubmitButton()) return;

                    onRequestChange();
                  }}
                  className="mc-btn green"
                  style={{
                    textTransform: "uppercase",
                    minHeight: "32px",
                    opacity: disableSubmitButton() ? 0.4 : 1
                  }}
                >
                  {isUpdatingStatus ? <Spinner /> : "Confirm"}
                </button>
              </div>
            </Flex>

            {/* <Button
              ref={cancelRef}
              onClick={() => {
                setRequest(null)
              }}
            >
              Cancel
            </Button>
            <Button
              className="mc-bn red"
              onClick={() => {
                setRequest(null)
              }}
              ml={3}
            >
              Delete
            </Button> */}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
