import { Grid, Spinner } from "@chakra-ui/react";
import { useFormik, getIn } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import _ from "lodash";
import { LabelField } from "../../../components/fields";
import { Box, Icon, Label } from "../../../components/elements";

import {
  addNewUser,
  changeUserActivePlanService
} from "../../../services/admin.service";
import { useShowToast } from "../../../hooks";
import CustomReactSelect from "../../../components/fields/CustomReactSelect";
import CountryArray from "../../../data/countries.json";

const thead = ["Level", "Commission Per Lot", ""];
const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  name: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Name is required"),
  password: Yup.string().min(6, "Too Short!").required("Required"),
  country: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
  address: Yup.string().required("Required")
});
const ChangeIbPlanForm = ({}) => {
  const showToast = useShowToast();

  const formik = useFormik({
    initialValues: {
      parent: 0,
      children: undefined,
      plan: undefined,
      country: undefined
    },
    onSubmit: async (values, actions) => {
      actions.setSubmitting(true);

      try {
        const result = await addNewUser(values);

        if (result?.data?.success) {
          showToast("Success", "User created successfully");
          actions.setSubmitting(false);
          actions.resetForm();
          return;
        } else {
          const e = result?.data?.data?.errors?.length
            ? result?.data?.data?.errors[0]
            : "Something went wrong";
          showToast("Error", e, "error");
        }
      } catch (e) {
        console.log(e);
      }

      actions.setSubmitting(false);
    },
    validationSchema: SignupSchema
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid gap={4} mb={4} maxWidth="500px">
          <Box>
            <LabelField
              label="Name"
              type="text"
              name="name"
              value={formik?.values?.name}
              onBlur={formik?.handleBlur}
              onChange={formik?.handleChange}
              errorMessage={
                formik?.errors?.name && formik?.touched?.name
                  ? formik?.errors?.name
                  : false
              }
            />
          </Box>

          <LabelField
            label="Email"
            type="email"
            name="email"
            value={formik?.values?.email}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            errorMessage={
              formik?.errors?.email && formik?.touched?.email
                ? formik?.errors?.email
                : false
            }
          />

          <LabelField
            label="Password"
            type="text"
            name="password"
            value={formik?.values?.password}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            errorMessage={
              formik?.errors?.password && formik?.touched?.password
                ? formik?.errors?.password
                : false
            }
          />

          <CustomReactSelect
            label="Country"
            errorMessage={
              formik?.errors?.country && formik?.touched?.country
                ? formik?.errors?.country
                : false
            }
            options={[
              { label: "Select Country", value: undefined },
              ..._.map(CountryArray, (_c) => {
                return {
                  label: _c?.name,
                  value: _c?.name
                };
              })
            ]}
            value={
              formik?.values?.country
                ? {
                    label: formik?.values?.country,
                    value: formik?.values?.country
                  }
                : undefined
            }
            onChange={(e) => {
              formik?.setFieldValue("country", e?.value);
              formik?.setFieldTouched("country");
            }}
          />

          <Box>
            <LabelField
              label="Phone"
              type="number"
              name="phone"
              value={formik?.values?.phone}
              onBlur={formik?.handleBlur}
              onChange={formik?.handleChange}
              errorMessage={
                formik?.errors?.phone && formik?.touched?.phone
                  ? formik?.errors?.phone
                  : false
              }
            />
          </Box>

          <Box>
            <LabelField
              label="Address"
              type="text"
              name="address"
              value={formik?.values?.address}
              onBlur={formik?.handleBlur}
              onChange={formik?.handleChange}
              errorMessage={
                formik?.errors?.address && formik?.touched?.address
                  ? formik?.errors?.address
                  : false
              }
            />
          </Box>

          <Box style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ marginLeft: "auto" }}></div>

            <button
              type="submit"
              className="mc-btn primary"
              style={{ minHeight: "42px" }}
            >
              {formik?.isSubmitting ? <Spinner /> : "Add User"}
            </button>
          </Box>
        </Grid>
      </form>
    </>
  );
};

export default ChangeIbPlanForm;
