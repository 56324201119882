import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import {
  Anchor,
  Heading,
  Box,
  Text,
  Input,
  Image,
  Icon,
  Button
} from "../elements";
import { map } from "lodash";
import moment from "moment";
import { Badge, Grid } from "@chakra-ui/react";

export default function ProductsTable(props) {
  const { thead, tbody, data, onChangeLeverage, onChangePassword } = props;

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table product">
        <Thead className="mc-table-head primary">
          <Tr>
            {thead.map((item, index) => (
              <Th className="d-none d-md-table-cell" key={index}>
                {item}
              </Th>
            ))}
            <Th>
              <div className="d-md-none">ACCOUNT</div>
            </Th>
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {map(data, (item, index) => (
            <Tr key={item?.id}>
              <Td>
                <b> {item?.account_id}</b>
                <br />
                {item?.is_ib ? "(IB Account)" : ""}

                <div
                  className="d-flex d-md-none"
                  style={{ flexDirection: "column" }}
                >
                  {item.platform}
                  <div>
                    {!item._leverage ? (
                      <>
                        <Badge
                          display="inline-block"
                          variant="solid"
                          colorScheme="gray"
                        >
                          DISABLED
                        </Badge>
                      </>
                    ) : (
                      <Badge
                        display="inline-block"
                        variant="solid"
                        colorScheme="green"
                      >
                        {item.status}
                      </Badge>
                    )}
                  </div>
                  Leverage: {item._leverage ?? "-"} <br />
                  <div>
                    {moment(item.created_at).format("Do MMM YYYY")}
                    <br />
                    {moment(item.created_at).format("h:mm:ss a")}
                  </div>
                  <br />
                  {item._leverage ? (
                    <Grid gap={2} maxWidth="120px">
                      <button
                        type="button"
                        className="mc-btn primary btn-sm"
                        style={{
                          display: "inline-block",
                          fontSize: "10px"
                        }}
                        onClick={() => {
                          if (onChangeLeverage) {
                            onChangeLeverage(item);
                          }
                        }}
                      >
                        Change Leverage
                      </button>{" "}
                      <button
                        type="button"
                        className="mc-btn primary"
                        style={{ display: "inline-block", fontSize: "10px" }}
                        onClick={() => {
                          if (onChangePassword) {
                            onChangePassword(item);
                          }
                        }}
                      >
                        Change Password
                      </button>{" "}
                    </Grid>
                  ) : (
                    <div></div>
                  )}
                </div>
              </Td>
              <Td className="d-none d-md-table-cell">{item.platform} </Td>

              <Td className="d-none d-md-table-cell">
                <Text style={{ textAlign: "right" }}>
                  {item._leverage ?? "-"}
                </Text>
              </Td>

              <Td className="d-none d-md-table-cell">
                {moment(item.created_at).format("Do MMM YYYY, h:mm:ss a")}
              </Td>
              <Td className="d-none d-md-table-cell">
                {!item._leverage ? (
                  <>
                    <Badge
                      display="inline-block"
                      variant="solid"
                      colorScheme="gray"
                    >
                      DISABLED
                    </Badge>
                  </>
                ) : (
                  <Badge
                    display="inline-block"
                    variant="solid"
                    colorScheme="green"
                  >
                    {item.status}
                  </Badge>
                )}
              </Td>

              <Td className="d-none d-md-table-cell">
                {item._leverage ? (
                  <Grid gap={2} maxWidth="120px">
                    <button
                      type="button"
                      className="mc-btn primary"
                      style={{ display: "inline-block" }}
                      onClick={() => {
                        if (onChangeLeverage) {
                          onChangeLeverage(item);
                        }
                      }}
                    >
                      Change Leverage
                    </button>{" "}
                    <button
                      type="button"
                      className="mc-btn primary"
                      onClick={() => {
                        if (onChangePassword) {
                          onChangePassword(item);
                        }
                      }}
                    >
                      Change Password
                    </button>{" "}
                  </Grid>
                ) : (
                  <div></div>
                )}
              </Td>
            </Tr>
          ))}

          {!data?.length ? (
            <Tr>
              <Td colSpan={thead?.length}>
                <div style={{ textAlign: "center", width: "100%" }}>
                  No Accounts present
                </div>
              </Td>
            </Tr>
          ) : null}
        </Tbody>
      </Table>
    </Box>
  );
}
