import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Tab, Tabs, Form } from "react-bootstrap";
import { Breadcrumb, Pagination } from "../../../components";
import PageLayout from "../../../layouts/PageLayout";
import data from "../../../data/master/admin/payments.json";
import { Box, Item, Anchor, Button } from "../../../components/elements";
import { CardLayout, TabCard } from "../../../components/cards";
import {
  EcommerceCard,
  SalesCard,
  ProductsCard,
  RevenueCard,
  ActivityCard,
  OrdersCard
} from "../../../components/cards";
import ClientsTable from "../../../components/admin/Clients";
import AdminAccountsPage from "../../../components/admin/Accounts";
import PaymentsPage from "../../../components/admin/Payments";
import {
  Skeleton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Spinner
} from "@chakra-ui/react";
import { LabelField } from "../../../components/fields";
import DepositTable from "./table";
import { useQuery } from "react-query";
import {
  approveRejectRequestService,
  getAllIBPlans,
  getAllIBRequestsService,
  getAllIbPlanService,
  getAllUsersServices,
  getRequestsByType,
  makeUserIbService
} from "../../../services/admin.service";
import { useShowToast } from "../../../hooks";
import _ from "lodash";
import ChangeIbPlanForm from "./add-new-user-form";

export default function AdminAddNewUser() {
  return (
    <PageLayout
      title="Add New User"
      breadcrumb={[
        {
          title: "home",
          path: "/admin/dashboard"
        },
        {
          title: "Users"
        }
      ]}
    >
      <Row>
        <DepositsPaymentPage />
      </Row>
    </PageLayout>
  );
}

const Loader = () => {
  return <Skeleton minHeight="500px" />;
};

const DepositsPaymentPage = () => {
  return (
    <>
      <Col xl={12}>
        <>
          <ChangeIbPlanForm />
        </>
      </Col>
    </>
  );
};
