import { Col, Row } from "react-bootstrap";
import PageLayout from "../../../../layouts/PageLayout";
import { Box, Item, Anchor, Button } from "../../../../components/elements";
import { Breadcrumb } from "../../../../components";
import { CardLayout } from "../../../../components/cards";
import TransferCreditsForm from "./transfer-credit-from";
import { useQuery } from "react-query";
import { adminGetAllAccounts } from "../../../../services/admin.service";
import { getPaymentMethodsService } from "../../../../services/client";
import { Skeleton } from "@chakra-ui/react";

const WalletTransfer = () => {
  const { isLoading, error, data, refetch } = useQuery(["admin-accounts"], () =>
    adminGetAllAccounts()
  );

  const paymentQuery = useQuery(["admin-payment-methods"], () =>
    //getPaymentMethodsService()
    getPaymentMethodsService()
  );

  const accounts = data?.data?.data?.accounts?.data?.data ?? [];

  const methods = paymentQuery?.data?.data?.data?.accounts?.data ?? [];
  return (
    <>
      <PageLayout
        title="Add/Remove Wallet Balance"
        breadcrumb={[
          {
            title: "Home",
            path: "/admin/dashoard"
          },
          {
            title: "Add/Remove Wallet Balance"
          }
        ]}
      >
        <Col xl={12}>
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <TransferCreditsForm accounts={accounts} methods={methods} />
            )}
          </>
        </Col>
      </PageLayout>
    </>
  );
};

export default WalletTransfer;

const Loader = () => {
  return <Skeleton minHeight="500px" />;
};
