import { Grid, Spinner, Text } from "@chakra-ui/react";
import { useFormik, getIn } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import _ from "lodash";
import { LabelField } from "../../../components/fields";
import { Box, Icon, Label } from "../../../components/elements";

import {
  addNewUser,
  adminCreateDepositWithdrawalRequest,
  adminCreateInternalTransferService,
  adminCreateTradingAccountService,
  adminGetLiveAccountBalanceByAccountIDAndUserId,
  changeUserActivePlanService
} from "../../../services/admin.service";
import { useShowToast } from "../../../hooks";
import CustomReactSelect from "../../../components/fields/CustomReactSelect";

const thead = ["Level", "Commission Per Lot", ""];

const SignupSchema = Yup.object().shape({
  from_account: Yup.number().required("Required"),
  to_account: Yup.number().required("Required"),
  amount: Yup.number().required("Required")
});

// accounts methods
const ChangeIbPlanForm = ({ accounts, type }) => {
  const showToast = useShowToast();
  const [isFetchingBalance, setIsFetchingBalance] = useState(false);
  const [account, setAccount] = useState(null);

  const formik = useFormik({
    initialValues: {
      from_account: null,
      to_account: null,
      amount: 0,
      comment: ""
    },
    onSubmit: async (values, actions) => {
      if (formik?.values?.from_account === formik?.values?.to_account) {
        formik.setFieldError(
          "to_account",
          "From and to account cannot be same"
        );
        actions.setSubmitting(false);
        return;
      }

      if (_.isEmpty(account)) {
        formik.setFieldError(
          "from_account",
          "Please select an account with balance."
        );
        actions.setSubmitting(false);
        return;
      }

      if (account?.balance < parseInt(values?.amount)) {
        formik.setFieldError(
          "from_account",
          "Withdraw amount should be less than account balance."
        );
        actions.setSubmitting(false);
        return;
      }

      const toAcc = _.find(accounts, (_acc) => {
        return _acc.id === parseInt(formik?.values?.to_account) ? true : false;
      });

      if (account?.is_live != toAcc?.is_live) {
        formik.setFieldError(
          "from_account",
          "Please select same type of account i.e. only live or demo"
        );
        actions.setSubmitting(false);
        return;
      }

      actions.setSubmitting(true);

      try {
        const result = await adminCreateInternalTransferService({ ...values });

        if (result?.data?.success) {
          showToast("Success", "Request created successfully");
          actions.setSubmitting(false);
          actions.resetForm();
          return;
        }
      } catch (e) {
        console.log(e);
      }

      showToast("Error", "Something went wrong", "error");
      actions.setSubmitting(false);
    },
    validationSchema: SignupSchema
  });

  useEffect(() => {
    if (formik?.values?.from_account) {
      fetchFromAccount();
    }
  }, [formik?.values?.from_account]);

  const allAccountsOptions = useMemo(() => {
    return [
      {
        label: "Select Account",
        value: undefined
      },
      ..._.map(_.sortBy(accounts, "account_id"), (_u) => {
        return {
          label: `${_u?.account_id} ${_u?.name} ${
            _u?.is_live ? "(LIVE)" : "(DEMO)"
          }`,
          value: _u?.id
        };
      })
    ];
  }, [accounts]);

  const toAccountsOption = useMemo(() => {
    if (formik?.values.from_account) {
      const account = _.find(accounts, (acc) => {
        return acc?.id === formik?.values.from_account ? true : false;
      });

      if (account) {
        return [
          {
            value: null,
            label: "Select to Account"
          },
          ..._.map(
            _.sortBy(
              _.filter(accounts, (acc) => {
                return acc?.user_id == account?.user_id ? true : false;
              }),
              "account_id"
            ),
            (_u) => {
              return {
                label: `${_u?.account_id} ${_u?.name} ${
                  _u?.is_live ? "(LIVE)" : "(DEMO)"
                }`,
                value: _u?.id
              };
            }
          )
        ];
      }
    }
  }, [formik?.values.from_account]);

  useEffect(() => {
    formik.setFieldValue("to_account", null);
  }, [formik?.values?.from_account]);

  const fetchFromAccount = async () => {
    //
    setAccount(null);

    const acc = _.find(accounts, (acc) => {
      return acc.id === parseInt(formik?.values?.from_account) ? true : false;
    });

    if (acc) {
      setIsFetchingBalance(true);
      try {
        const result = await adminGetLiveAccountBalanceByAccountIDAndUserId(
          acc?.user_id,
          acc?.account_id
        );

        const accountWithBalance = _.find(
          result?.data?.data?.accounts?.data?.data,
          (acc) => {
            return acc?.id === parseInt(formik?.values?.from_account)
              ? true
              : false;
          }
        );

        if (accountWithBalance) {
          setAccount(accountWithBalance);
          setIsFetchingBalance(false);
          return;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getAccountOption = (_id) => {};

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid gap={4} my={4} maxWidth="500px">
          <Box>
            <CustomReactSelect
              label="From Account"
              name="from_account"
              options={allAccountsOptions}
              value={_.find(
                allAccountsOptions,
                "acc.id",
                formik?.values?.from_account
                  ? parseInt(formik?.values.from_account)
                  : undefined
              )}
              errorMessage={
                formik?.errors?.from_account && formik?.touched?.from_account
                  ? formik?.errors?.from_account
                  : false
              }
              onChange={(e) => {
                formik.setFieldTouched("from_account");
                formik.setFieldValue("from_account", e?.value);
              }}
              onBlur={() => {
                formik.setFieldTouched("from_account");
              }}
            />
            <Text fontSize="xs">
              {isFetchingBalance
                ? "Fetching balance...."
                : account
                ? `Current account balance is ${account?.balance}.`
                : ""}
            </Text>
          </Box>
          <Box>
            <CustomReactSelect
              label="To Account"
              name="to_account"
              options={toAccountsOption}
              value={_.find(
                toAccountsOption,
                "acc.id",
                formik?.values?.to_account
                  ? parseInt(formik?.values.to_account)
                  : undefined
              )}
              errorMessage={
                formik?.errors?.to_account && formik?.touched?.to_account
                  ? formik?.errors?.to_account
                  : false
              }
              onChange={(e) => {
                formik.setFieldTouched("to_account");
                formik.setFieldValue("to_account", e?.value);
              }}
              onBlur={() => {
                formik.setFieldTouched("to_account");
              }}
            />
          </Box>
          <LabelField
            label="Amount"
            type="number"
            name="amount"
            value={formik?.values?.amount}
            onBlur={formik?.handleBlur}
            onChange={formik?.handleChange}
            errorMessage={
              formik?.errors?.amount && formik?.touched?.amount
                ? formik?.errors?.amount
                : false
            }
          />
          <Box>
            <LabelField
              label="comment"
              type="text"
              name="comment"
              value={formik?.values?.comment}
              onBlur={formik?.handleBlur}
              onChange={formik?.handleChange}
              errorMessage={
                formik?.errors?.comment && formik?.touched?.comment
                  ? formik?.errors?.comment
                  : false
              }
            />
          </Box>

          <Box style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ marginLeft: "auto" }}></div>

            <button
              type="submit"
              className="mc-btn primary"
              style={{ minHeight: "42px" }}
            >
              {formik?.isSubmitting || isFetchingBalance ? <Spinner /> : type}
            </button>
          </Box>
        </Grid>
      </form>
    </>
  );
};

export default ChangeIbPlanForm;
