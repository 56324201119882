import React, { useContext } from "react";
import { MultipleMenu, Logout } from "../components/sidebar";
import { DrawerContext } from "../context/Drawer";
import Section from "../components/elements/Section";
import data from "../data/master/sidebar.json";
import adminData from "../data/master/admin-sidebar.json";
import ibData from "../data/master/ib-sidebar.json";
import allData from "../data/master/sidebar copy.json";
import useSession from "../hooks/useSession";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

export default function Sidebar() {
  const { drawer } = useContext(DrawerContext);
  const [user, isLoading] = useSession();

  const navigate = useNavigate();

  const isAdminRoute = () => {
    if (!user) return [];

    const URL = window?.location?.href ?? "";

    if (URL?.includes("/admin/")) return allowedAdminRoutes(user);

    if (URL?.includes("/ib/")) {
      return [
        {
          title: "IB",
          menu: [
            ...ibData?.navs[0]?.menu,
            {
              href: "/ib/view-link",
              icon: "insert_link",
              text: "View Link"
            },
            {
              href: "/ib/back-to-client",
              icon: "arrow_back",
              text: "Back To Client"
            }
          ]
        }
      ];
    }
    return [
      {
        title: ibData?.navs?.title ?? "",
        menu: [
          ...data?.navs[0]?.menu,
          {
            href: "/to/ib",
            icon: "arrow_back",
            text: "IB Dashboard"
          }
        ]
      }
    ];
  };

  const navs = isAdminRoute();

  return (
    <Section
      as="aside"
      className={`mc-sidebar thin-scrolling ${drawer ? "active" : ""}`}
    >
      <MultipleMenu data={[...navs]} />
      <Logout data={data?.button} />
    </Section>
  );
}

export const allowedAdminRoutes = (user) => {
  let routes = [];

  const userPermissionsName = _.map(user?.permissions, "name");

  const allowedStringRoutes = [];

  routes = _.map(adminData?.navs[0]?.menu ?? [], (_r) => {
    return {
      ..._r,
      submenu:
        !_r?.submenu || !_r?.submenu?.length
          ? undefined
          : _.filter(_r?.submenu, (sub) => {
              if (sub?.permissions?.length) {
                return _.intersection(userPermissionsName, sub?.permissions)
                  ?.length
                  ? true
                  : false;
              }

              return true;
            })
    };
  });

  routes = _.filter(routes, (_r) => {
    if (_r?.submenu?.length === 0) return false;

    if (_r?.permissions?.length) {
      return _.intersection(userPermissionsName, _r?.permissions)?.length
        ? true
        : false;
    }

    return true;
  });

  return [
    {
      ...adminData?.navs[0],
      menu: routes
    }
  ];
};
