import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Button, Item } from "../../components/elements";
import { CardLayout, CardHeader } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import useSession from "../../hooks/useSession";
import { Skeleton } from "@chakra-ui/react";
import { useQuery } from "react-query";
import {
  getMyAccounts,
  getOpenTradesService,
  getTradesService
} from "../../services/client";
import _ from "lodash";
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "../../components/elements/Table";
import convertDateToSeconds from "../../utils/convertSecondsToDate";
import { LabelField } from "../../components/fields";

export default function OpenTrades() {
  const [user] = useSession();

  return (
    <PageLayout
      title="Open Trades"
      breadcrumb={[
        {
          title: "Home",
          path: "/dashboard"
        },
        {
          title: "Open Trades Trade"
        }
      ]}
    >
      {user ? <TransactionsContainer user={user} /> : null}
    </PageLayout>
  );
}

const TransactionsLoader = () => {
  return <Skeleton minHeight="500px" />;
};

const TransactionsContainer = ({ user }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAccount, setSelectAccount] = useState(undefined);
  const [forceUpdate, setForceUpdate] = useState(true);

  const { isLoading, error, data } = useQuery(
    ["open-trades", currentPage, forceUpdate],
    () => getOpenTradesService(user?.id),
    {
      refetchInterval: 200000
    }
  );

  if (isLoading) return <TransactionsLoader />;

  return (
    <Row>
      <Col xl={12}>
        <MyTradesTable trades={data?.data?.data?.trades ?? []} />
      </Col>
    </Row>
  );
};

const thead = [
  "Login ID",
  "Volume",
  "Symbol",
  "Price",
  "Stop Loss",
  "Take Profit",
  "Profit",
  "Position ID",
  "Action",
  "Comment"
];

const MyTradesTable = ({ trades }) => {
  return (
    <>
      <Box className="mc-table-responsive">
        <Table className="mc-table product">
          <Thead className="mc-table-head primary">
            <Tr>
              {thead.map((item, index) => (
                <Th key={index}>{item}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody className="mc-table-body even">
            {trades?.length ? (
              <>
                {_.map(trades, (_r) => {
                  return (
                    <Tr key={_r?.id}>
                      <Td>{_r?.loginId}</Td>

                      <Td>{_r?.volume ? parseInt(_r?.volume) / 10000 : "-"}</Td>
                      <Td>{_r?.symbol}</Td>
                      <Td>{_r?.price}</Td>
                      <Td>{_r?.sl ?? 0}</Td>
                      <Td>{_r?.tp ?? 0}</Td>
                      <Td>{_r?.profit ?? 0}</Td>
                      <Td>{_r?.positionId}</Td>
                      <Td>{_r?.action == 0 ? "Buy" : "Sell"}</Td>
                      <Td>{_r?.comment}</Td>
                    </Tr>
                  );
                })}
              </>
            ) : (
              <Tr>
                <Td colSpan={thead?.length ?? 1}>
                  <div style={{ textAlign: "center", width: "100%" }}>
                    No Data found
                  </div>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
