import React, { useState } from "react"
import {
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
} from "../../../components/elements/Table"
import {
  Anchor,
  Heading,
  Box,
  Text,
  Input,
  Image,
  Icon,
  Button,
} from "../../../components/elements"
import _ from "lodash"
import { Avatar, Flex, Grid } from "@chakra-ui/react"
import formatDateHelper from "../../../utils/formatDate"

const thead = ["User", "Commission", "Lot", "Position", "Deal"]
// {req: {}, status}
const DepositTable = ({ requests, onChangeStatus }) => {
  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table product">
        <Thead className="mc-table-head primary">
          <Tr>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {requests?.length ? (
            <>
              {_.map(requests, (_request) => {
                //const _request = _r?.length ? _r[0] : null

                return (
                  <Tr key={_request?.id}>
                    <Td>
                      <Flex
                        align="center"
                        direction={["column", "column", "row", "row"]}
                      >
                        <Avatar mr={2} name={_request?.name} />
                        <Box>
                          <div>{_request?.name}</div>
                          <div>{_request?.email}</div>
                        </Box>
                      </Flex>
                    </Td>
                    <Td>{_request?.commission}</Td>
                    <Td>{_request?.vlot}</Td>
                    <Td>{_request?.positionID}</Td>
                    <Td>{_request?.deal}</Td>
                    {/* <Td>{_request?.reference}</Td>
                    <Td>{formatDateHelper(_request?.created_at)}</Td>
                    <Td>
                      {_request?.status === "PENDING" ? (
                        <Grid gap={1} width="100%" maxWidth="100px">
                          <button
                            onClick={() => {
                              onChangeStatus({
                                req: { ..._request },
                                status: "accept",
                              })
                            }}
                            className="mc-btn green"
                          >
                            {" "}
                            Accept
                          </button>

                          <button
                            onClick={() => {
                              onChangeStatus({
                                req: { ..._request },
                                status: "reject",
                              })
                            }}
                            className="mc-btn red"
                          >
                            Reject
                          </button>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Td> */}
                  </Tr>
                )
              })}
            </>
          ) : (
            <Tr>
              <Td colSpan={thead?.length ?? 1}>
                <div style={{ textAlign: "center", width: "100%" }}>
                  No Data found
                </div>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  )
}

export default DepositTable
