import React, { useEffect, useMemo, useRef, useState } from "react";
import PageLayout from "../../../layouts/PageLayout";
import { Col, Row } from "react-bootstrap";
import { CardHeader, CardLayout } from "../../../components/cards";
import { Anchor, Box, Item, List, Text } from "../../../components/elements";
import { Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { LabelField, LabelTextarea } from "../../../components/fields";
import {
  changeSupportTicketStatus,
  createTicketComment,
  getSupportTicketDetails,
  uploadImageForSupportTicket
} from "../../../services/ib";
import { useTimeAgo, useTimeAgoGrouped } from "../../../utils/useTimeAgo";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useShowToast } from "../../../hooks";
import {
  Divider,
  Center,
  Spinner,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Badge,
  Avatar,
  Image,
  AvatarGroup
} from "@chakra-ui/react";
import useSession from "../../../hooks/useSession";
import _ from "lodash";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import { DuelText, RoundAvatar } from "../../../components";
import moment from "moment";

const SupportOneView = () => {
  const params = useParams();
  const [data, setData] = useState();
  const { duration, time } = useTimeAgo(data?.created_at);
  const dateFormatedData = useTimeAgoGrouped(data?.replies);
  const [isFetching, setIsFetching] = useState(false);

  const [user] = useSession();

  useEffect(() => {
    if (user) {
      const p = _.find(user?.permissions, (_p) =>
        _p?.name === "view.supports" ? true : false
      );

      if (!p) {
        window.location.href = "/admin/dashboard";
      }
    }
  }, [user]);

  const updatePermission = useMemo(() => {
    const p = _.find(user?.permissions, (_p) =>
      _p?.name === "update.supports" ? true : false
    );

    return p;
  }, [user]);

  useEffect(() => {
    if (params.id) fetchTicketDetails();
  }, [params.id]);

  async function fetchTicketDetails() {
    setIsFetching(true);
    const result = await getSupportTicketDetails(params.id);

    if (result?.data?.success) {
      const data = result?.data?.data?.support;
      setData(data);
    } else {
      console.log("failed to fetch!");
    }
    setIsFetching(false);
  }

  return (
    <PageLayout
      title={"Support Ticket #" + params?.id ?? ""}
      breadcrumb={[
        {
          title: "Home",
          path: "/admin/dashboard"
        },
        {
          title: "Support",
          path: "/admin/support"
        },
        {
          title: params?.id ?? "",
          path: "/admin/support/" + params?.id
        }
      ]}
    >
      <Row>
        <Col xl={12}>
          {isFetching && !data ? (
            <Center marginTop="20px">
              <Spinner size="xl" />
            </Center>
          ) : (
            <>
              <Box className="mc-message-chat">
                <Box className="mc-message-chat-header">
                  <DuelText
                    title={data?.title}
                    descrip={`created ${duration}`}
                    size="xs"
                    gap="4px"
                  />
                  <Box className="mc-message-chat-action-group"></Box>
                </Box>
                <List className="mc-message-chat-list thin-scrolling">
                  <Item className="mc-message-chat-item">
                    <Box className="mc-message-chat-group">
                      <Box className="mc-message-chat-text">
                        <Text>
                          {data?.title ? <b>{data?.title}</b> : null} <br />
                          {data?.support_text ?? ""}
                          <Flex>
                            <AvatarGroup gap={4} mt={4}>
                              {_.map(data?.media, (m) => {
                                return (
                                  <>
                                    <Zoom>
                                      <Image
                                        boxSize="50px"
                                        objectFit="cover"
                                        src={getURL(m?.media_url)}
                                        alt="Support"
                                      />
                                    </Zoom>
                                  </>
                                );
                              })}
                            </AvatarGroup>
                          </Flex>
                        </Text>
                      </Box>

                      <Text className="mc-message-chat-datetime">
                        {moment(data?.created_at).format(
                          "DD MMM, YYYY hh:MM:SS A"
                        )}
                      </Text>
                    </Box>
                  </Item>

                  {_.map(data?.replies, (r) => (
                    <Item
                      className={`mc-message-chat-item ${
                        r?.user_id === data?.user_id
                          ? null
                          : "mc-message-chat-item-user"
                      }`}
                    >
                      <Box className="mc-message-chat-group">
                        <Box className="mc-message-chat-text">
                          <Text>
                            {r?.title ? (
                              <>
                                <b>{r?.title}</b>
                                <br />
                              </>
                            ) : null}
                            {r?.support_text ?? ""}

                            <Flex>
                              <AvatarGroup gap={4} mt={4}>
                                {_.map(r?.media, (m) => {
                                  return (
                                    <>
                                      <Zoom>
                                        <Image
                                          boxSize="50px"
                                          objectFit="cover"
                                          src={getURL(m?.media_url)}
                                          alt="Support"
                                        />
                                      </Zoom>
                                    </>
                                  );
                                })}
                              </AvatarGroup>
                            </Flex>
                          </Text>
                        </Box>

                        <Text className="mc-message-chat-datetime">
                          {moment(r?.created_at)
                            .utcOffset("+05:30")
                            .format("DD MMM, YYYY hh:MM:SS A")}{" "}
                          <br />
                          <b>{r?.name}</b>
                        </Text>
                      </Box>
                    </Item>
                  ))}
                </List>
              </Box>

              {data ? (
                <>
                  <Divider style={{ borderColor: "lightgray" }} />

                  {updatePermission ? (
                    <CreateComment
                      data={data}
                      setData={setData}
                      id={params.id}
                      refetch={fetchTicketDetails}
                    />
                  ) : null}
                </>
              ) : (
                <Text>
                  Unable to get details of ticket id <b>#{params.id}</b>
                </Text>
              )}
            </>
          )}
        </Col>
      </Row>
    </PageLayout>
  );
};
const getURL = (name) => {
  //return `http://localhost/nord-fx/storage/support/${name}`
  return `http://client.orbitglobalfx.com/crm/storage/support/${name}`;
};

const CommentCard = ({ data, time }) => {
  const [user] = useSession();
  const currentUser = data.user_id === user.id;
  const firstComment = !data.parent_id;

  return (
    <Flex
      style={{
        flexDirection: currentUser ? "row-reverse" : "",
        marginBottom: "10px"
      }}
    >
      <Box
        style={{
          width: firstComment ? "100%" : "",
          minWidth: "20%",
          maxWidth: firstComment ? "auto" : "80%"
        }}
      >
        <Flex
          alignItems="center"
          flexDirection={currentUser ? "row-reverse" : ""}
          gap={2}
        >
          <Avatar
            size="sm"
            name={data.name}
            color="black"
            bg="#f9f9fb"
            border="1px solid lightgray"
          />
          <Box style={{ width: "100%" }}>
            <Text as="small" style={{ fontWeight: "500" }}>
              {data.name}
            </Text>
            <Box
              style={{
                border: "1px solid lightgray",
                borderRadius: "5px",
                padding: "5px 10px",
                backgroundColor: "#f9f9fb"
              }}
            >
              {data.title ? (
                <Text style={{ fontWeight: "500", lineHeight: 1.4 }}>
                  {data.title}
                </Text>
              ) : (
                ""
              )}
              <Text style={{ fontSize: "14px" }}>{data.support_text}</Text>
              <Text
                style={{
                  fontSize: "10px",
                  textAlign: "right",
                  color: "gray",
                  lineHeight: "1.2"
                }}
              >
                {firstComment ? time : data.time}
              </Text>
            </Box>

            <Flex>
              <AvatarGroup gap={4} mt={4}>
                {_.map(data?.media, (m) => {
                  return (
                    <>
                      <Zoom>
                        <Image
                          boxSize="50px"
                          objectFit="cover"
                          src={getURL(m?.media_url)}
                          alt="Support"
                        />
                      </Zoom>
                    </>
                  );
                })}
              </AvatarGroup>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

const commentSchema = Yup.object().shape({
  //title: Yup.string().required("Title is required!"),
  description: Yup.string().required("Description is required!")
});

const CreateComment = ({ data, setData, id, refetch }) => {
  const [user] = useSession();
  const showToast = useShowToast();
  const [isOpen, setIsOpen] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const handleOpen = () => setIsOpen(!isOpen);
  const imageUploadRef = useRef();

  const formik = useFormik({
    initialValues: {
      // title: "",
      description: "",
      priority: ""
    },
    enableReinitialize: true,
    validationSchema: commentSchema,
    onSubmit: (values, actions) => {
      handleComment(values, actions);
    }
  });

  const handleComment = async (values, actions) => {
    actions.setSubmitting(true);

    try {
      const parent_id = data.id;
      const priority = data.priority;

      const result = await createTicketComment(
        values?.title,
        values?.description,
        priority,
        parent_id
      );

      if (result?.data?.success) {
        if (values?.images) {
          try {
            const uploadFileResult = await uploadImageForSupportTicket(
              result?.data?.data?.support?.id,
              values?.images
            );
          } catch (error) {}
        }

        await refetch();

        showToast("Success", "Ticket comment created successfully");
        actions?.resetForm();
        actions.setSubmitting(false);

        if (imageUploadRef?.current?.value) {
          imageUploadRef.current.value = "";
        }

        return;
      }
    } catch (error) {}

    showToast("Error", "Something went wrong", "error");
    actions.setSubmitting(false);
  };

  const handleClose = () => {
    if (data?.status === "RESOLVED" || isClosed) {
      showToast("Warning", "The ticket is already closed!", "warning");
    } else {
      handleOpen();
    }
  };

  return (
    <Box style={{ marginTop: "20px" }}>
      <Flex justifyContent="space-between">
        <CardHeader title="Add a comment!" />

        <div></div>
      </Flex>
      <Flex flexDirection="column" gap={2}>
        {/* <LabelField
          label="Title"
          type="text"
          fieldSize="w-100 h-md"
          name={"title"}
          value={formik?.values.title}
          onChange={formik?.handleChange}
          errorMessage={
            formik?.errors?.title && formik?.touched?.title
              ? formik?.errors?.title
              : false
          }
        /> */}
        <LabelTextarea
          fieldSize="w-100"
          label="Description"
          name={"description"}
          value={formik?.values.description}
          onChange={formik?.handleChange}
          errorMessage={
            formik?.errors?.description && formik?.touched?.description
              ? formik?.errors?.description
              : false
          }
        />

        <LabelField
          ref={imageUploadRef}
          label="Attach a file"
          type="file"
          fieldSize="w-100 h-md"
          accept=".jpg, .jpeg, .png"
          name="images"
          onBlur={formik.handleBlur}
          multiple="multiple"
          onChange={(e) => {
            formik.setFieldTouched("images", true);

            if (e?.currentTarget?.files > 5) {
              formik?.setFieldError(
                "images",
                "Maximum of only 5 files are allowed"
              );
            }

            formik.setFieldValue("images", e?.currentTarget?.files ?? null);
          }}
          errorMessage={
            formik?.errors?.images && formik?.touched?.images
              ? formik?.errors?.images
              : false
          }
        />
      </Flex>

      <Box style={{ marginTop: "20px" }}>
        <button
          type="button"
          className={"mc-btn primary h-sm"}
          disabled={formik?.isSubmitting}
          onClick={formik.handleSubmit}
          style={{ display: "flex", alignItems: "center" }}
        >
          {formik?.isSubmitting ? <Spinner size="xs" /> : "Submit"}
        </button>
      </Box>

      <Flex justifyContent="space-between">
        <div></div>

        {data?.status === "RESOLVED" || isClosed ? (
          <>
            <Badge colorScheme="green">Ticket Is Resolved</Badge>
          </>
        ) : (
          <button
            type="button"
            className={"mc-btn red h-sm"}
            disabled={formik?.isSubmitting}
            onClick={handleClose}
            style={{ display: "flex", alignItems: "center" }}
          >
            Mark Ticket As Resolved
          </button>
        )}
      </Flex>
      <CloseTicket
        isOpen={isOpen}
        handleOpen={handleOpen}
        status={data.status}
        id={id}
        setIsClosed={setIsClosed}
      />
    </Box>
  );
};

const CloseTicket = ({ isOpen, handleOpen, status, id, setIsClosed }) => {
  const showToast = useShowToast();

  const handleClose = async () => {
    try {
      const result = await changeSupportTicketStatus(id, "RESOLVED");

      if (result?.data?.success) {
        showToast("Success", "Ticket closed successfully");
        handleOpen();
        setIsClosed(true);
        return;
      }
    } catch (error) {}

    showToast("Error", "Something went wrong", "error");
    handleOpen();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Close Ticket</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Do you want to close this ticket <b>#{id}</b>?
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" onClick={handleClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SupportOneView;
