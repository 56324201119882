import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Button, Item } from "../../components/elements";
import { CardLayout, CardHeader } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import useSession from "../../hooks/useSession";
import { Skeleton } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getMyAccounts, getTradesService } from "../../services/client";
import _ from "lodash";
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "../../components/elements/Table";
import convertDateToSeconds from "../../utils/convertSecondsToDate";
import { LabelField } from "../../components/fields";

export default function MyTrades() {
  const [user] = useSession();

  return (
    <PageLayout
      title="My Trades"
      breadcrumb={[
        {
          title: "Home",
          path: "/dashboard"
        },
        {
          title: "My Trades"
        }
      ]}
    >
      {user ? <TransactionsContainer user={user} /> : null}
    </PageLayout>
  );
}

const TransactionsLoader = () => {
  return <Skeleton minHeight="500px" />;
};

const TransactionsContainer = ({ user }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAccount, setSelectAccount] = useState(undefined);
  const [forceUpdate, setForceUpdate] = useState(true);

  const { isLoading, error, data } = useQuery(
    ["my-trades", currentPage, forceUpdate],
    () => getTradesService(currentPage, user?.id, selectAccount),
    {
      refetchInterval: 4500
    }
  );

  const myAccountsQuery = useQuery(["myAccounts", user], () =>
    getMyAccounts(user?.id)
  );

  if (isLoading) return <TransactionsLoader />;

  const accounts =
    myAccountsQuery?.data?.data?.data?.accounts?.data?.data ?? [];

  return (
    <Row>
      <Col xl={12}>
        <>
          {/* <CardHeader title="My Trades" /> */}
          {/* <br /> */}

          <Row>
            <Col xl={3}>
              <LabelField
                value={selectAccount}
                onChange={(e) => {
                  setSelectAccount(e?.target?.value);
                }}
                label="Select Account"
                option={[
                  {
                    value: undefined,
                    title: "All Accounts"
                  },
                  ..._.map(accounts ?? [], (_acc) => {
                    return {
                      value: _acc?.account_id,
                      title: _acc?.account_id
                    };
                  })
                ]}
              />
            </Col>

            <Col xl={3}> </Col>
            <Col xl={3}> </Col>
            <Col xl={3}>
              <div style={{ textAlign: "right" }}>
                <br />
                <Button
                  onClick={() => {
                    setForceUpdate((v) => !v);
                  }}
                  className="mc-btn primary ml-auto"
                >
                  Search
                </Button>
              </div>
            </Col>
          </Row>
          <br />
          <MyTradesTable
            trades={
              _.filter(data?.data?.data?.trades[0]?.data ?? [], (_t) =>
                !_t?.volume ? false : true
              ) ?? []
            }
          />
          <br />
          <Pagination
            lastPage={data?.data?.data?.trades[0]?.last_page}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      </Col>
    </Row>
  );
};

const thead = [
  "Login ID",
  "Volume",
  "Symbol",
  "Open Price",
  "Stop Loss",
  "Take Profit",
  "Position ID",
  "Deal",
  "Order",
  "Action",
  "Entry",
  "Comment",
  "Profit",
  "Time"
];

const MyTradesTable = ({ trades }) => {
  return (
    <>
      <Box className="mc-table-responsive">
        <Table className="mc-table product">
          <Thead className="mc-table-head primary">
            <Tr>
              {thead.map((item, index) => (
                <Th key={index}>{item}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody className="mc-table-body even">
            {trades?.length ? (
              <>
                {_.map(trades, (_r) => {
                  return (
                    <Tr key={_r?.id}>
                      <Td>{_r?.loginId}</Td>

                      <Td>{_r?.volume ? parseInt(_r?.volume) / 10000 : "-"}</Td>
                      <Td>{_r?.symbol}</Td>
                      <Td>{_r?.price}</Td>
                      <Td>{_r?.sl ?? 0}</Td>
                      <Td>{_r?.tp ?? 0}</Td>

                      <Td>{_r?.positionID}</Td>
                      <Td>{_r?.deal}</Td>
                      <Td>{_r?.order_id}</Td>
                      <Td>{_r?.action == 0 ? "Buy" : "Sell"}</Td>
                      <Td>Close</Td>

                      <Td>{_r?.comment}</Td>
                      <Td>{_r?.profit}</Td>
                      <Td>{convertDateToSeconds(_r?.time)}</Td>
                    </Tr>
                  );
                })}
              </>
            ) : (
              <Tr>
                <Td colSpan={thead?.length ?? 1}>
                  <div style={{ textAlign: "center", width: "100%" }}>
                    No Data found
                  </div>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
