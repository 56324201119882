import api from "../api";

export const getMyIBRequestService = () => {
  return api.get("/v1/ib/my-ib-request");
};

export const createIBRequestService = () => {
  return api.post("/v1/request-ib");
};

export const getMyIBWalletBalanceService = () => {
  return api.get("/v1/ib-wallet-balance");
};

export const createIBWithdrawRequest = (amount, useAccId) => {
  return api.post("/v1/ib-wallet-request", {
    amount: amount,
    user_account_id: useAccId
  });
};

export const getMyIBTransactionsService = () => {
  return api.get("/v1/ib/transactions?limit=5000");
};

export const getMyTradeReports = () => {
  return api.get("/v1/ib/trades?hasChild=1");
};

export const getMyIBCommissions = () => {
  return api.get("/v1/all-commissions?hasChild=true");
};

export const getMyIBDashboardService = () => {
  return api.get("/v1/ib/dashboard");
};

export const getMyIBTreeService = (user_id) => {
  let param = "";

  if (user_id) {
    param = "user_id=" + user_id;
  }

  return api.get("/v1/ib/my-tree?" + param);
};

export const getMyTradesCommissionService = () => {
  return api.get("/v1/my-commission");
};

export const getSupportTickets = (
  user_id,
  page = 1,
  priority,
  title,
  status
) => {
  let query = "?page=" + page;

  if (user_id) {
    query += "&user_id=" + user_id;
  }

  if (priority) {
    query += "&priority=" + priority;
  }

  if (title) {
    query += "&title=" + title;
  }

  if (status) {
    query += "&status=" + status;
  }

  return api.get("v1/support" + query);
};

export const getSupportTicketDetails = (id) => {
  return api.get(`v1/support-single?id=${id}`);
};

export const createTicketComment = (title, desc, priority, parent_id) => {
  return api.post("v1/support", {
    title: title,
    support_text: desc,
    priority: priority,
    parent_id: parent_id
  });
};

export const createSupportTicket = (title, desc, priority) => {
  return api.post("v1/support", {
    title: title,
    support_text: desc,
    priority: priority
  });
};

export const uploadImageForSupportTicket = (id, images) => {
  const form = new FormData();
  form.append("id", id);

  if (images?.length) {
    for (let index = 0; index < images.length; index++) {
      form.append("images[]", images[index]);
    }
  }

  return api.post("v1/upload-support", form, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const changeSupportTicketStatus = (id, status) => {
  return api.put("v1/support-change-status", {
    id: id,
    status: status
  });
};

export const getMyIBKYCList = () => {
  return api.get("v1/kyc-users-count");
};

export const getMyIBTradeList = () => {
  return api.get("v1/trades-client-count");
};

export const getMyIBTransactionReport = (
  type,
  includeChild,
  fromDate,
  toDate,
  user_id
) => {
  let query = `type=${type}`;

  if (includeChild) {
    query += `&includeChild=${includeChild}`;
  }

  if (fromDate) {
    query += `&toDate=${fromDate}`;
  }

  if (toDate) {
    query += `&fromDate=${toDate}`;
  }

  if (user_id) {
    query += `&user_id=${user_id}`;
  }

  return api.get(`v1/ibportal?${query}`);
};

export const getTransactionDetailedListService = (
  type,
  includeChild,
  fromDate,
  toDate,
  user_id,
  page
) => {
  let query = `type=${type}`;

  if (includeChild) {
    query += `&includeChild=${includeChild}`;
  }

  if (fromDate) {
    query += `&toDate=${fromDate}`;
  }

  if (toDate) {
    query += `&fromDate=${toDate}`;
  }

  if (user_id) {
    query += `&user_id=${user_id}`;
  }

  if (page) {
    query += `&page=${page}`;
  }

  return api.get(`v1/transactions-client?${query}`);
};

export const getIBDashboardService = () => {
  return api.get(`v1/ib-dashboard`);
};

export const getIbKYCReportsService = (
  page,
  fromDate,
  toDate,
  user_id,
  downloadFile
) => {
  let query = `page=${page}`;

  if (fromDate) {
    query += `&toDate=${fromDate}`;
  }

  if (toDate) {
    query += `&fromDate=${toDate}`;
  }

  if (user_id) {
    query += `&user_id=${user_id}`;
  }

  if (downloadFile) {
    query += `&cvs_download=true`;
  }

  return api.get(`v1/ib-report-kyc?${query}`);
};

export const getIbTradesReportsService = (
  page,
  fromDate,
  toDate,
  user_id,
  isChildren,
  downloadFile
) => {
  let query = `page=${page}&isChildren=true`;

  if (fromDate) {
    query += `&toDate=${fromDate}`;
  }

  if (toDate) {
    query += `&fromDate=${toDate}`;
  }

  if (user_id) {
    query += `&user_id=${user_id}`;
  }

  if (isChildren) {
    query += `&isChildren=${isChildren}`;
  }

  if (downloadFile) {
    query += `&cvs_download=true`;
  }

  return api.get(`v1/ib-report-trade?${query}`);
};

export const getIbTransactionsReportsService = (
  type,
  page,
  fromDate,
  toDate,
  user_id,
  isChildren,
  downloadFile
) => {
  let query = `page=${page}&type=${type}&status=ACCEPTED`;

  if (isChildren) {
    query += `&isChildren=${isChildren}`;
  }

  if (fromDate) {
    query += `&toDate=${fromDate}`;
  }

  if (toDate) {
    query += `&fromDate=${toDate}`;
  }

  if (user_id) {
    query += `&ib_id=${user_id}`;
  }

  if (downloadFile) {
    query += `&cvs_download=true`;
  }

  return api.get(`v1/ib-report-transactions?${query}`);
};
