import axios from "axios";
import api from "../api";

export const dashboardService = () => {
  return api.get("/v1/dashboard");
};

export const getMyAccounts = (userId) => {
  return api.get(`/v1/live-demo-accounts?user_id=${userId}`);
};

export const getAccountPlans = () => {
  return api.get("/v1/account-master");
};

export const createTradingAccountService = (id, group, type = 0) => {
  return api.post("/v1/new-account-create", {
    id: id,
    group: group,
    type: type
  });
};

export const getPaymentMethodsService = () => {
  return api.get("/v1/accounts");
};

export const createRequestService = (
  amount,
  account_id,
  user_account_id,
  reference,
  type = "DEPOSIT",
  screenShot,
  transaction_flow = undefined
) => {
  let data = {
    amount: amount,
    type: type,
    account_id: account_id,
    user_account_id: user_account_id,
    reference: reference
  };

  if (screenShot) {
    data["image"] = screenShot;
  }

  if (transaction_flow) {
    data["transaction_flow"] = transaction_flow;
  }

  console.log(data);

  return api.post("/v1/requests", data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const createTransferRequestService = (from, to, amount) => {
  return api.post("/v1/transfer-fund", {
    from_account: from,
    to_account: to,
    amount: amount
  });
};

export const getMyTransactions = (type, status, page) => {
  let params = "";

  if (type) {
    params += `&type=${type}`;
  }

  if (status) {
    params += `&status=${status}`;
  }

  if (page) {
    params += `&page=${page}`;
  }

  return api.get("/v1/transactions?" + params);
};

export const uploadDocumentService = (
  documenttitle0,
  document0,
  documenttitle1,
  document1
) => {
  let body = {
    documenttitle0: documenttitle0,
    document0: document0
  };

  if (document1) {
    body["documenttitle1"] = documenttitle1;
    body["document1"] = document1;
  }

  return api.post("/v1/upload-documents", body, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const getMyUploadedDocuments = () => {
  return api.get("/v1/mydocuments");
};

export const getMyLiveDemoAccounts = (user_id) => {
  let query = "";

  if (user_id) {
    query += `user_id=${user_id}`;
  }

  return api.get(`/v1/ib/live-demo-accounts?${query}`);
};

export const changeMT5LeverageService = (account_id, leverage) => {
  return api.post("/v1/change-leverage", {
    account_id: account_id,
    leverage: leverage
  });
};

export const changeMT5PasswordService = (
  loginId,
  investor_password,
  master_password
) => {
  return api.post("/v1/change-mt-password", {
    account_id: loginId,
    investor_password: investor_password,
    master_password: master_password
  });
};

export const getMyDocumentsService = () => {
  return api.get("/v1/mydocuments");
};

export const getMyTradingAccounts = () => {
  return api.get("/v1/my-accounts?limit=10000");
};

export const getTradesService = (
  page,
  user_id = null,
  selectAccount = undefined,
  startDate,
  endDate,
  cvs_download = false
) => {
  let query = `page=${page}`;

  if (user_id) {
    query += `&user_id=${user_id}`;
  }

  if (selectAccount) {
    query += `&loginId=${selectAccount}`;
  }

  if (startDate) {
    query += `&from_date=${startDate}`;
  }

  if (endDate) {
    query += `&to_date=${endDate}`;
  }

  if (cvs_download) {
    query += `&cvs_download=true`;
  }

  return api.get(`/v1/trades?${query}`);
};

export const getOpenTradesService = (user_id) => {
  return api.get(`/v1/open-trades?id=${user_id}`);
};

export const verifyTransactionOtpService = (id, otp) => {
  return api.post(`v1/verify-transactions`, { id, otp });
};

export const registerForAITradingSystemService = (data) => {
  return api.post(`v1/sent-mail`, data);
};

export const userDashboardService = () => {
  return api.get(`v1/dashboard`);
};

export const getTotalTransactions = () => {
  return api.get(`v1/wallet-request-data`);
};

export const getNotifications = (page, name, from, to) => {
  let query = "?page=" + page;

  if (name) {
    query += "&message=" + name;
  }

  if (from) {
    query += "&from_date=" + from;
  }
  if (to) {
    query += "&to_date=" + to;
  }
  return api.get(`v1/notifications` + query);
};

export const getLogger = (page, name, from, to) => {
  let query = "?page=" + page;

  if (name) {
    query += "&search=" + name;
  }

  if (from) {
    query += "&from_date=" + from;
  }
  if (to) {
    query += "&to_date=" + to;
  }

  return api.get(`v1/logger` + query);
};

export const getSumsubAccessTokenService = () => {
  return api.get(`v1/applicantid`);
};
