import React, { useEffect, useMemo, useRef, useState } from "react";
import { Row, Col, Tab, Tabs, Form } from "react-bootstrap";
import { Breadcrumb, Pagination } from "../../../components";
import PageLayout from "../../../layouts/PageLayout";
import data from "../../../data/master/admin/payments.json";
import { Box, Item, Anchor, Button } from "../../../components/elements";
import { CardLayout, TabCard } from "../../../components/cards";
import {
  EcommerceCard,
  SalesCard,
  ProductsCard,
  RevenueCard,
  ActivityCard,
  OrdersCard
} from "../../../components/cards";
import ClientsTable from "../../../components/admin/Clients";
import AdminAccountsPage from "../../../components/admin/Accounts";
import PaymentsPage from "../../../components/admin/Payments";
import {
  Skeleton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Spinner
} from "@chakra-ui/react";
import { LabelField } from "../../../components/fields";
import DepositTable from "./table";
import { useQuery } from "react-query";
import {
  adminGetAllAccounts,
  approveRejectRequestService,
  getAllAccountsService,
  getAllIBPlans,
  getAllIBRequestsService,
  getAllIBStructuresService,
  getAllIbPlanService,
  getAllUsersServices,
  getRequestsByType,
  makeUserIbService
} from "../../../services/admin.service";
import { useShowToast } from "../../../hooks";
import _ from "lodash";
import ChangeIbPlanForm from "./add-new-user-form";
import {
  getAccountPlans,
  getPaymentMethodsService
} from "../../../services/client";

export default function AdminChangeIBCommissionPlans({ type = "DEPOSIT" }) {
  return (
    <PageLayout title="Change IB Commission Plan">
      <Row>
        <DepositsPaymentPage type={type} />
      </Row>
    </PageLayout>
  );
}

const Loader = () => {
  return <Skeleton minHeight="500px" />;
};

const DepositsPaymentPage = ({ type }) => {
  const { isLoading, error, data, refetch } = useQuery(["admin-ib-plans"], () =>
    getAllIBStructuresService()
  );

  const allAdminIBUsers = useQuery(["all-admin-ib-users"], () =>
    getAllUsersServices(1, "IB", 500000, "", "")
  );

  const structures = useMemo(() => {
    let _structures = [];

    if (data?.data?.data?.ibstructureplan?.data?.length) {
      _structures = _.map(data?.data?.data?.ibstructureplan?.data, (s) => {
        return {
          ...s,
          commissionsLevels: Object.values(
            _.groupBy(_.sortBy(s?.commissionsLevels ?? [], "level"), "level")
          )
        };
      });
    }

    return _structures;
  }, [data, isLoading]);

  const allIBUsers = allAdminIBUsers?.data?.data?.data?.users?.data ?? [];

  return (
    <>
      <Col xl={12}>
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <ChangeIbPlanForm ibs={allIBUsers} allPlans={structures} />
          )}
        </>
      </Col>
    </>
  );
};
