import React, { useMemo, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Box, Button, Item } from "../../components/elements";
import { CardLayout, CardHeader } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import useSession from "../../hooks/useSession";
import { Skeleton } from "@chakra-ui/react";
import { useQuery } from "react-query";
import {
  getLogger,
  getNotifications,
  getTradesService
} from "../../services/client";
import _ from "lodash";
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "../../components/elements/Table";
import convertDateToSeconds from "../../utils/convertSecondsToDate";
import { LabelField } from "../../components/fields";
import { getAllAccountsService } from "../../services/admin.service";
import DateRangePicker from "../../components/DateRangePicker";
import formatDateHelper, { formatDateToYYYYMMDD } from "../../utils/formatDate";
import { useEffect } from "react";
import downloadFile from "../../utils/downloadFile";
import convertJsonToCsv from "../../utils/convertJsonToCsv";
import moment from "moment";

export default function AdminLogger() {
  const [user] = useSession();

  return (
    <PageLayout
      title={"Logger"}
      breadcrumb={[
        {
          title: "Home",
          path: "/admin/dashboard"
        },
        {
          title: "Logger"
        }
      ]}
    >
      {user ? <TransactionsContainer user={user} /> : null}
    </PageLayout>
  );
}

const TransactionsLoader = () => {
  return <Skeleton minHeight="500px" />;
};

const TransactionsContainer = ({ user }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(true);
  const [selectAccount, setSelectedAccount] = useState(null);
  const [forcedSearch, setForcedSearch] = useState(false);
  const [dateRange, setDateRange] = useState([undefined, undefined]);
  const [name, setName] = useState("");
  const [finalState, setFinalState] = useState({
    dateRange,
    name,
    from: undefined,
    to: undefined
  });
  const [isDownloading, setIsDownloading] = useState(false);

  const { isLoading, error, data } = useQuery(
    ["admin-logger", currentPage, forcedSearch, finalState],
    () =>
      getLogger(currentPage, finalState.name, finalState.from, finalState.to),
    {
      refetchInterval: 4500
    }
  );

  useEffect(() => {
    setFinalState({
      dateRange,
      name,
      from:
        dateRange?.length == 2 && dateRange[0]
          ? moment(dateRange[0]).format("YYYY-MM-DD")
          : undefined,
      to:
        dateRange?.length == 2 && dateRange[1]
          ? moment(dateRange[1]).format("YYYY-MM-DD")
          : undefined
    });
  }, [forcedSearch]);

  const logs = data?.data?.data?.logs ?? {};

  if (isLoading) return <TransactionsLoader />;

  return (
    <Row>
      <Col xl={12}>
        <CardLayout>
          <Row>
            <Col xl={3}>
              <DateRangePicker
                label="Select Date Range"
                selected={dateRange[0]}
                onChange={setDateRange}
                startDate={dateRange[0]}
                endDate={dateRange[1]}
                selectsRange
                //inline
              />
            </Col>

            <Col xl={3}>
              <LabelField
                type="text"
                label="Search"
                value={name}
                onChange={(e) => {
                  setName(e?.target?.value);
                }}
              />
            </Col>

            <Col xl={3}> </Col>

            <Col xl={3}>
              <div style={{ textAlign: "right" }}>
                <br />
                &emsp;
                <Button
                  onClick={() => {
                    setCurrentPage(1);
                    setForcedSearch((v) => !v);
                  }}
                  className="mc-btn primary ml-auto"
                >
                  Search
                </Button>
              </div>
            </Col>
          </Row>

          <br />

          <MyTradesTable trades={logs.data ?? []} />
          <Pagination
            lastPage={logs?.last_page}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </CardLayout>
      </Col>
    </Row>
  );
};

const thead = ["Message", "Created At"];

const MyTradesTable = ({ trades }) => {
  return (
    <>
      <Box className="mc-table-responsive">
        <Table className="mc-table product">
          <Thead className="mc-table-head primary">
            <Tr>
              <Th>Message</Th>
              <Th>Created At</Th>
            </Tr>
          </Thead>
          <Tbody className="mc-table-body even">
            {trades?.length ? (
              <>
                {_.map(trades, (_r) => {
                  return (
                    <Tr key={_r?.id}>
                      <Td>{_r?.log_msg}</Td>
                      <Td>{formatDateHelper(_r?.created_at)}</Td>
                    </Tr>
                  );
                })}
              </>
            ) : (
              <Tr>
                <Td colSpan={thead?.length ?? 1}>
                  <div style={{ textAlign: "center", width: "100%" }}>
                    No Data found
                  </div>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
