import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Tab, Tabs, Form } from "react-bootstrap";
import { Breadcrumb, Pagination } from "../../../components";
import PageLayout from "../../../layouts/PageLayout";
import data from "../../../data/master/admin/payments.json";
import { Box, Item, Anchor, Button } from "../../../components/elements";
import { CardLayout, TabCard } from "../../../components/cards";
import {
  EcommerceCard,
  SalesCard,
  ProductsCard,
  RevenueCard,
  ActivityCard,
  OrdersCard
} from "../../../components/cards";
import ClientsTable from "../../../components/admin/Clients";
import AdminAccountsPage from "../../../components/admin/Accounts";
import PaymentsPage from "../../../components/admin/Payments";
import {
  Skeleton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Spinner
} from "@chakra-ui/react";
import { LabelField } from "../../../components/fields";
import DepositTable from "./table";
import { useQuery } from "react-query";
import {
  approveRejectRequestService,
  getAllIBPlans,
  getAllIBRequestsService,
  getAllIbPlanService,
  getAllUsersServices,
  getRequestsByType,
  makeUserIbService
} from "../../../services/admin.service";
import { useShowToast } from "../../../hooks";
import _ from "lodash";
import ChangeIbPlanForm from "./add-new-user-form";
import { getAccountPlans } from "../../../services/client";

export default function AdminAddTradingAccount() {
  return (
    <PageLayout
      title="Create New Trading Account"
      breadcrumb={[
        {
          title: "Home",
          path: "/admin/dsahboard"
        },
        {
          title: "Create New Trading Account",
          path: "#"
        }
      ]}
    >
      <Row>
        <DepositsPaymentPage />
      </Row>
    </PageLayout>
  );
}

const Loader = () => {
  return <Skeleton minHeight="500px" />;
};

const DepositsPaymentPage = () => {
  const { isLoading, error, data } = useQuery(["account_plans"], () =>
    getAccountPlans()
  );

  const userQuery = useQuery(["all-users"], () =>
    getAllUsersServices(1, "", 3000)
  );

  const plans = data?.data?.data?.account_master ?? [];

  const users = userQuery?.data?.data?.data?.users?.data ?? [];

  return (
    <>
      <Col xl={12}>
        <>
          {useQuery.isLoading || isLoading ? (
            <Loader />
          ) : (
            <ChangeIbPlanForm plans={plans} users={users} />
          )}
        </>
      </Col>
    </>
  );
};
